import { createAction } from "redux-actions"
import CommonApis from "../apis/CommonApis"

/* action type */
export const SET_CROP_PLAN_LIST = "@elefarm/purchaseSpend/SET_CROP_PLAN_LIST"
export const SET_COST_PLAN_LIST = "@elefarm/purchaseSpend/SET_COST_PLAN_LIST"

export const SET_PURCHASE_COST_LIST = "@elefarm/purchaseSpend/SET_PURCHASE_COST_LIST"
export const SET_PURCHASE_COST = "@elefarm/purchaseSpend/SET_PURCHASE_COST"
export const SET_PURCHASE_COST_DETAIL = "@elefarm/purchaseSpend/SET_PURCHASE_COST_DETAIL"

/* action function */
export const setCropPlanList = createAction(SET_CROP_PLAN_LIST)
export const setCostPlanList = createAction(SET_COST_PLAN_LIST)

export const setPurchaseCostList = createAction(SET_PURCHASE_COST_LIST)
export const setPurchaseCost = createAction(SET_PURCHASE_COST)
export const setPurchaseCostDetail = createAction(SET_PURCHASE_COST_DETAIL)

/* action api function */
// 작기별구매지출계획 리스트
export const getCropPlanList = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/purchase/costPlan/list", param)
  if (result.code === 0) {
    dispatch(setCropPlanList(result.data.costPlanList))
    return true
  } else return false
}
// 작기별구매지출계획 구매/지출 리스트
export const getCostPlanList = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/purchase/costPlan/kind/list", param)
  if (result.code === 0) {
    dispatch(setCostPlanList(result.data.costPlanKindList))
    return true
  } else return false
}
// 작기별구매지출계획 구매/지출 추가
export const addCostPlan = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/purchase/costPlan/kind/add", param)
  if (result.code === 0) {
    return true
  } else return false
}
// 작기별구매지출계획 구매/지출 수정
export const editCostPlan = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/purchase/costPlan/kind/mod", param)
  if (result.code === 0) {
    return true
  } else return false
}
// 작기별구매지출계획 구매/지출 삭제
export const delCostPlan = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/purchase/costPlan/kind/del", param)
  if (result.code === 0) {
    return true
  } else return false
}

// 구매지출등록 리스트
export const getPurchaseCostList = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/purchase/cost/list", param)
  if (result.code === 0) {
    dispatch(setPurchaseCostList(result.data.costList))
    return true
  } else return false
}
// 구매지출등록 추가
export const addPurchaseCost = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/purchase/cost/add", param)
  if (result.code === 0) {
    return true
  } else return false
}
// 구매지출등록 정보
export const getPurchaseCost = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/purchase/cost/info", param)
  if (result.code === 0) {
    dispatch(setPurchaseCost(result.data.dataList))
    return true
  } else return false
}
// 구매지출등록 수정
export const editPurchaseCost = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/purchase/cost/mod", param)
  if (result.code === 0) {
    return true
  } else return false
}
// 구매지출등록 삭제
export const delPurchaseCost = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/purchase/cost/del", param)
  if (result.code === 0) {
    return true
  } else return false
}
// 구매지출등록 구매지출상세 정보
export const getPurchaseCostDetail = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/purchase/costDetail/info", param)
  if (result.code === 0) {
    dispatch(setPurchaseCostDetail(result.data.costDetailInfo))
    return true
  } else return false
}
// 구매지출등록 구매지출상세 삭제
export const delPurchaseCostDetail = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/purchase/costDetail/del", param)
  if (result.code === 0) {
    return true
  } else return false
}
// 작기별구매지출계획 리스트 엑셀 다운로드
export const downloadPurchaseExpendListExcel = (param) => async (dispatch) => {
  const result = await CommonApis.getApi(`/purchase/cost/excel/download`, param, { responseType: "blob" })
  return result
}
