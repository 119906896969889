import { createAction } from "redux-actions"
import CommonApis from "../apis/CommonApis"

/* action type */
// 표준작기정보
export const INIT_STD_CROP_LIST = "@elefarm/cropManage/INIT_STD_CROP_LIST"
export const SET_STD_CROP_LIST = "@elefarm/cropManage/SET_STD_CROP_LIST"
export const INIT_STD_CROP = "@elefarm/cropManage/INIT_STD_CROP"
export const SET_STD_CROP = "@elefarm/cropManage/SET_STD_CROP"

export const INIT_STD_CROP_GROWTH_LIST = "@elefarm/cropManage/INIT_STD_CROP_GROWTH_LIST"
export const SET_STD_CROP_GROWTH_LIST = "@elefarm/cropManage/SET_STD_CROP_GROWTH_LIST"
export const INIT_STD_CROP_GROWTH = "@elefarm/cropManage/INIT_STD_CROP_GROWTH"
export const SET_STD_CROP_GROWTH = "@elefarm/cropManage/SET_STD_CROP_GROWTH"

export const INIT_STD_CROP_PEST_LIST = "@elefarm/cropManage/INIT_STD_CROP_PEST_LIST"
export const SET_STD_CROP_PEST_LIST = "@elefarm/cropManage/SET_STD_CROP_PEST_LIST"
export const INIT_STD_CROP_PEST = "@elefarm/cropManage/INIT_STD_CROP_PEST"
export const SET_STD_CROP_PEST = "@elefarm/cropManage/SET_STD_CROP_PEST"

export const INIT_STD_CROP_ENV_LIST = "@elefarm/cropManage/INIT_STD_CROP_ENV_LIST"
export const SET_STD_CROP_ENV_LIST = "@elefarm/cropManage/SET_STD_CROP_ENV_LIST"
export const INIT_STD_CROP_ENV = "@elefarm/cropManage/INIT_STD_CROP_ENV"
export const SET_STD_CROP_ENV = "@elefarm/cropManage/SET_STD_CROP_ENV"

export const INIT_STD_CROP_HRM_LIST = "@elefarm/cropManage/INIT_STD_CROP_HRM_LIST"
export const SET_STD_CROP_HRM_LIST = "@elefarm/cropManage/SET_STD_CROP_HRM_LIST"
export const INIT_STD_CROP_HRM = "@elefarm/cropManage/INIT_STD_CROP_HRM"
export const SET_STD_CROP_HRM = "@elefarm/cropManage/SET_STD_CROP_HRM"
// 작기정보
export const INIT_CROP_LIST = "@elefarm/cropManage/INIT_CROP_LIST"
export const SET_CROP_LIST = "@elefarm/cropManage/SET_CROP_LIST"
export const INIT_CROP = "@elefarm/cropManage/INIT_CROP"
export const SET_CROP = "@elefarm/cropManage/SET_CROP"

export const INIT_CROP_GROWTH_LIST = "@elefarm/cropManage/INIT_CROP_GROWTH_LIST"
export const SET_CROP_GROWTH_LIST = "@elefarm/cropManage/SET_CROP_GROWTH_LIST"
export const INIT_CROP_GROWTH = "@elefarm/cropManage/INIT_CROP_GROWTH"
export const SET_CROP_GROWTH = "@elefarm/cropManage/SET_CROP_GROWTH"

export const INIT_CROP_PEST_LIST = "@elefarm/cropManage/INIT_CROP_PEST_LIST"
export const SET_CROP_PEST_LIST = "@elefarm/cropManage/SET_CROP_PEST_LIST"
export const INIT_CROP_PEST = "@elefarm/cropManage/INIT_CROP_PEST"
export const SET_CROP_PEST = "@elefarm/cropManage/SET_CROP_PEST"

export const INIT_CROP_ENV_LIST = "@elefarm/cropManage/INIT_CROP_ENV_LIST"
export const SET_CROP_ENV_LIST = "@elefarm/cropManage/SET_CROP_ENV_LIST"
export const INIT_CROP_ENV = "@elefarm/cropManage/INIT_CROP_ENV"
export const SET_CROP_ENV = "@elefarm/cropManage/SET_CROP_ENV"

export const INIT_CROP_HRM_LIST = "@elefarm/cropManage/INIT_CROP_HRM_LIST"
export const SET_CROP_HRM_LIST = "@elefarm/cropManage/SET_CROP_HRM_LIST"
export const INIT_CROP_HRM = "@elefarm/cropManage/INIT_CROP_HRM"
export const SET_CROP_HRM = "@elefarm/cropManage/SET_CROP_HRM"
// 재배일지
export const SET_CROP_REPORT_LIST = "@elefarm/cropManage/SET_CROP_REPORT_LIST"
export const SET_CROP_REPORT_INFO = "@elefarm/cropManage/SET_CROP_REPORT_INFO"
export const SET_CROP_REPORT_COMBO_LIST = "@elefarm/cropManage/SET_CROP_REPORT_COMBO_LIST"

// 작기마감
export const INIT_CROP_CLOSE_LIST = "@elefarm/cropManage/INIT_CROP_CLOSE_LIST"
export const SET_CROP_CLOSE_LIST = "@elefarm/cropManage/SET_CROP_CLOSE_LIST"

/* action function */
// 표준작기정보
export const initStdCropList = createAction(INIT_STD_CROP_LIST)
export const setStdCropList = createAction(SET_STD_CROP_LIST)
export const initStdCrop = createAction(INIT_STD_CROP)
export const setStdCrop = createAction(SET_STD_CROP)

export const initStdCropGrowthList = createAction(INIT_STD_CROP_GROWTH_LIST)
export const setStdCropGrowthList = createAction(SET_STD_CROP_GROWTH_LIST)
export const initStdCropGrowth = createAction(INIT_STD_CROP_GROWTH)
export const setStdCropGrowth = createAction(SET_STD_CROP_GROWTH)

export const initStdCropPestList = createAction(INIT_STD_CROP_PEST_LIST)
export const setStdCropPestList = createAction(SET_STD_CROP_PEST_LIST)
export const initStdCropPest = createAction(INIT_STD_CROP_PEST)
export const setStdCropPest = createAction(SET_STD_CROP_PEST)

export const initStdCropEnvList = createAction(INIT_STD_CROP_ENV_LIST)
export const setStdCropEnvList = createAction(SET_STD_CROP_ENV_LIST)
export const initStdCropEnv = createAction(INIT_STD_CROP_ENV)
export const setStdCropEnv = createAction(SET_STD_CROP_ENV)


export const initStdCropHRMList = createAction(INIT_STD_CROP_HRM_LIST)
export const setStdCropHRMList = createAction(SET_STD_CROP_HRM_LIST)
export const initStdCropHRM = createAction(INIT_STD_CROP_HRM)
export const setStdCropHRM = createAction(SET_STD_CROP_HRM)
// 작기정보
export const initCropList = createAction(INIT_CROP_LIST)
export const setCropList = createAction(SET_CROP_LIST)
export const initCrop = createAction(INIT_CROP)
export const setCrop = createAction(SET_CROP)

export const initCropGrowthList = createAction(INIT_CROP_GROWTH_LIST)
export const setCropGrowthList = createAction(SET_CROP_GROWTH_LIST)
export const initCropGrowth = createAction(INIT_CROP_GROWTH)
export const setCropGrowth = createAction(SET_CROP_GROWTH)

export const initCropPestList = createAction(INIT_CROP_PEST_LIST)
export const setCropPestList = createAction(SET_CROP_PEST_LIST)
export const initCropPest = createAction(INIT_CROP_PEST)
export const setCropPest = createAction(SET_CROP_PEST)

export const initCropEnvList = createAction(INIT_CROP_ENV_LIST)
export const setCropEnvList = createAction(SET_CROP_ENV_LIST)
export const initCropEnv = createAction(INIT_CROP_ENV)
export const setCropEnv = createAction(SET_CROP_ENV)

export const initCropHRMList = createAction(INIT_CROP_HRM_LIST)
export const setCropHRMList = createAction(SET_CROP_HRM_LIST)
export const initCropHRM = createAction(INIT_CROP_HRM)
export const setCropHRM = createAction(SET_CROP_HRM)
// 재배일지
export const setCropReportList = createAction(SET_CROP_REPORT_LIST)
export const setCropReportInfo = createAction(SET_CROP_REPORT_INFO)
export const setCropReportComboList = createAction(SET_CROP_REPORT_COMBO_LIST)
// 작기마감
export const initCropCloseList = createAction(INIT_CROP_CLOSE_LIST)
export const setCropCloseList = createAction(SET_CROP_CLOSE_LIST)

/* action api function */
// 표준작기정보 기본리스트
export const getStdCropList = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/product/stdCrop/list", param)
  if (result.code === 0) {
    dispatch(setStdCropList(result.data.stdCropList))
    return true
  } else return false
}
// 표준작기정보 기본추가
export const addStdCrop = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/product/stdCrop/add", param)
  if (result.code === 0) {
    return true
  } else return false
}
// 표준작기정보 기본정보
export const getStdCrop = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/product/stdCrop/info", param)
  if (result.code === 0) {
    dispatch(setStdCrop(result.data.stdCropInfo))
    return true
  } else return false
}
// 표준작기정보 기본정보수정
export const editStdCrop = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/product/stdCrop/mod", param)
  if (result.code === 0) {
    return true
  } else return false
}
// 표준작기정보 기본삭제
export const delStdCrop = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/product/stdCrop/del", param)
  if (result.code === 0) {
    return true
  } else return false
}
// 표준작기정보 생육리스트
export const getStdCropGrowthList = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/product/stdCropGrowth/list", param)
  if (result.code === 0) {
    dispatch(setStdCropGrowthList(result.data.stdCropGrowthList))
    return true
  } else return false
}
// 표준작기정보 생육추가
export const addStdCropGrowth = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/product/stdCropGrowth/add", param)
  if (result.code === 0) {
    return true
  } else return false
}
// 표준작기정보 생육정보
export const getStdCropGrowth = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/product/stdCropGrowth/info", param)
  if (result.code === 0) {
    dispatch(setStdCropGrowth(result.data.stdCropGrowthInfo))
    return true
  } else return false
}
// 표준작기정보 생육정보수정
export const editStdCropGrowth = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/product/stdCropGrowth/mod", param)
  if (result.code === 0) {
    return true
  } else return false
}
// 표준작기정보 생육삭제
export const delStdCropGrowth = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/product/stdCropGrowth/del", param)
  if (result.code === 0) {
    return true
  } else return false
}
// 표준작기정보 병해충리스트
export const getStdCropPestList = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/product/stdCropPest/list", param)
  if (result.code === 0) {
    dispatch(setStdCropPestList(result.data.stdCropPestList))
    return true
  } else return false
}
// 표준작기정보 병해충추가
export const addStdCropPest = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/product/stdCropPest/add", param)
  if (result.code === 0) {
    return true
  } else return false
}
// 표준작기정보 병해충정보
export const getStdCropPest = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/product/stdCropPest/info", param)
  if (result.code === 0) {
    dispatch(setStdCropPest(result.data.stdCropPestInfo))
    return true
  } else return false
}
// 표준작기정보 병해충정보수정
export const editStdCropPest = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/product/stdCropPest/mod", param)
  if (result.code === 0) {
    return true
  } else return false
}
// 표준작기정보 병해충삭제
export const delStdCropPest = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/product/stdCropPest/del", param)
  if (result.code === 0) {
    return true
  } else return false
}
// 표준작기정보 환경리스트
export const getStdCropEnvList = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/product/stdCropEnv/list", param)
  if (result.code === 0) {
    dispatch(setStdCropEnvList(result.data.stdCropEnvList))
    return true
  } else return false
}
// 표준작기정보 환경추가
export const addStdCropEnv = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/product/stdCropEnv/add", param)
  if (result.code === 0) {
    return true
  } else return false
}
// 표준작기정보 환경정보
export const getStdCropEnv = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/product/stdCropEnv/info", param)
  if (result.code === 0) {
    dispatch(setStdCropEnv(result.data.stdCropEnvInfo))
    return true
  } else return false
}
// 표준작기정보 환경정보수정
export const editStdCropEnv = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/product/stdCropEnv/mod", param)
  if (result.code === 0) {
    return true
  } else return false
}
// 표준작기정보 환경삭제
export const delStdCropEnv = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/product/stdCropEnv/del", param)
  if (result.code === 0) {
    return true
  } else return false
}
// 표준작기정보 인력관리리스트
export const getStdCropHRMList = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/product/stdCropHRM/list", param)
  if (result.code === 0) {
    dispatch(setStdCropHRMList(result.data.stdCropHRMList))
    return true
  } else return false
}
// 표준작기정보 인력관리추가
export const addStdCropHRM = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/product/stdCropHRM/add", param)
  if (result.code === 0) {
    return true
  } else return false
}
// 표준작기정보 인력관리정보
export const getStdCropHRM = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/product/stdCropHRM/info", param)
  if (result.code === 0) {
    dispatch(setStdCropHRM(result.data.stdCropHRMInfo))
    return true
  } else return false
}
// 표준작기정보 인력관리수정
export const editStdCropHRM = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/product/stdCropHRM/mod", param)
  if (result.code === 0) {
    return true
  } else return false
}
// 표준작기정보 인력관리삭제
export const delStdCropHRM = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/product/stdCropHRM/del", param)
  if (result.code === 0) {
    return true
  } else return false
}

// 작기정보 기본리스트
export const getCropList = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/product/crop/list", param)
  if (result.code === 0) {
    dispatch(setCropList(result.data.cropList))
    return true
  } else return false
}
// 작기정보 기본추가
export const addCrop = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/product/crop/add", param)
  if (result.code === 0) {
    return true
  } else return false
}
// 작기정보 기본정보
export const getCrop = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/product/crop/info", param)
  if (result.code === 0) {
    dispatch(setCrop(result.data.cropInfo))
    return true
  } else return false
}
// 작기정보 기본정보수정
export const editCrop = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/product/crop/mod", param)
  if (result.code === 0) {
    return true
  } else return false
}
// 작기정보 기본삭제
export const delCrop = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/product/crop/del", param)
  if (result.code === 0) {
    return true
  } else return false
}
// 작기정보 생육리스트
export const getCropGrowthList = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/product/cropGrowth/list", param)
  if (result.code === 0) {
    dispatch(setCropGrowthList(result.data.cropGrowthList))
    return true
  } else return false
}
// 작기정보 생육추가
export const addCropGrowth = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/product/cropGrowth/add", param)
  if (result.code === 0) {
    return true
  } else return false
}
// 작기정보 생육정보
export const getCropGrowth = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/product/cropGrowth/info", param)
  if (result.code === 0) {
    dispatch(setCropGrowth(result.data.cropGrowthInfo))
    return true
  } else return false
}
// 작기정보 생육정보수정
export const editCropGrowth = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/product/cropGrowth/mod", param)
  if (result.code === 0) {
    return true
  } else return false
}
// 작기정보 생육삭제
export const delCropGrowth = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/product/cropGrowth/del", param)
  if (result.code === 0) {
    return true
  } else return false
}
// 작기정보 병해충리스트
export const getCropPestList = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/product/cropPest/list", param)
  if (result.code === 0) {
    dispatch(setCropPestList(result.data.cropPestList))
    return true
  } else return false
}
// 작기정보 병해충추가
export const addCropPest = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/product/cropPest/add", param)
  if (result.code === 0) {
    return true
  } else return false
}
// 작기정보 병해충정보
export const getCropPest = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/product/cropPest/info", param)
  if (result.code === 0) {
    dispatch(setCropPest(result.data.cropPestInfo))
    return true
  } else return false
}
// 작기정보 병해충정보수정
export const editCropPest = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/product/cropPest/mod", param)
  if (result.code === 0) {
    return true
  } else return false
}
// 작기정보 병해충삭제
export const delCropPest = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/product/cropPest/del", param)
  if (result.code === 0) {
    return true
  } else return false
}
// 작기정보 환경리스트
export const getCropEnvList = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/product/cropEnv/list", param)
  if (result.code === 0) {
    dispatch(setCropEnvList(result.data.cropEnvList))
    return true
  } else return false
}
// 작기정보 환경추가
export const addCropEnv = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/product/cropEnv/add", param)
  if (result.code === 0) {
    return true
  } else return false
}
// 작기정보 환경정보
export const getCropEnv = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/product/cropEnv/info", param)
  if (result.code === 0) {
    dispatch(setCropEnv(result.data.cropEnvInfo))
    return true
  } else return false
}
// 작기정보 환경정보수정
export const editCropEnv = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/product/cropEnv/mod", param)
  if (result.code === 0) {
    return true
  } else return false
}
// 작기정보 환경삭제
export const delCropEnv = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/product/cropEnv/del", param)
  if (result.code === 0) {
    return true
  } else return false
}

// 작기정보 인력리스트
export const getCropHRMList = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/product/cropHRM/list", param)
  if (result.code === 0) {
    dispatch(setCropHRMList(result.data.cropHRMList))
    return true
  } else return false
}
// 작기정보 인력추가
export const addCropHRM = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/product/cropHRM/add", param)
  if (result.code === 0) {
    return true
  } else return false
}
// 작기정보 인력정보
export const getCropHRM = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/product/cropHRM/info", param)
  if (result.code === 0) {
    dispatch(setCropHRM(result.data.cropHRMInfo))
    return true
  } else return false
}
// 작기정보 인력수정
export const editCropHRM = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/product/cropHRM/mod", param)
  if (result.code === 0) {
    return true
  } else return false
}
// 작기정보 인력삭제
export const delCropHRM = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/product/cropHRM/del", param)
  if (result.code === 0) {
    return true
  } else return false
}

// 재배일지 리스트
export const getCropReportList = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/product/cropReport/list", param)
  if (result.code === 0) {
    dispatch(setCropReportList(result.data.cropReportList))
    return true
  } else return false
}
// 재배일지 정보
export const getCropReportInfo = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/product/cropReport/info", param)
  if (result.code === 0) {
    dispatch(setCropReportInfo(result.data.cropReportInfo))
    return true
  } else return false
}
// 재배일지 정보추가
export const addCropReport = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/product/cropReport/add", param)
  if (result.code === 0) {
    return true
  } else return false
}
// 재배일지 정보수정
export const editCropReport = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/product/cropReport/mod", param)
  if (result.code === 0) {
    return true
  } else return false
}
// 재배일지 삭제
export const delCropReport = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/product/cropReport/del", param)
  if (result.code === 0) {
    return true
  } else return false
}
// 재배일지 엑셀다운로드
export const getCropReportExcelDown = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/product/cropReport/excel/download", param, { responseType: "blob" })
  return result
}
// 재배일지 콤보리스트
export const getCropReportComboList = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/system/combo/list", param)
  if (result.code === 0) {
    if (param.queryKind) {
      switch (param.queryKind) {
        case "Crop":
          dispatch(setCropReportComboList(result.data.dataList))
          break
        case "WorkCenter":
          dispatch(setCropReportCenterComboList(result.data.dataList))
          break
      }
      return true
    } else return false
  }
}

// 작기마감 리스트
export const getCropCloseList = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/product/crop/close/list", param)
  if (result.code === 0) {
    dispatch(setCropCloseList(result.data.cropList))
    return true
  } else return false
}
// 작기마감 정보수정
export const editCropClose = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/product/crop/close/mod", param)
  if (result.code === 0) {
    return true
  } else return false
}
// 작기마감 엑셀다운로드
export const downloadCropCloseListExcel = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/product/crop/close/excel/download", param, { responseType: "blob" })
  return result
}
