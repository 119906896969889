import { combineReducers } from "redux"
import { penderReducer } from "redux-pender"
import CommonReducer from "./CommonReducer"
import ConfigReducer from "./ConfigReducer"
import SignReducer from "./SignReducer"
import SystemSettingReducer from "./SystemSettingReducer"
import DefaultInfoReducer from "./DefaultInfoReducer"
import EnvControlReducer from "./EnvControlReducer"
import CropManageReducer from "./CropManageReducer"
import ProductionReducer from "./ProductionReducer"
import SalesManageReducer from "./SalesManageReducer"
import PurchaseSpendReducer from "./PurchaseSpendReducer"
import StatusInfoReducer from "./StatusInfoReducer"
import ChartReducer from "./ChartReducer" // 차트관련 Reducer - 차건담 2023.06.19

export default combineReducers({
  pender: penderReducer,
  CommonReducer,
  ConfigReducer,
  SignReducer,
  SystemSettingReducer,
  DefaultInfoReducer,
  EnvControlReducer,
  CropManageReducer,
  ProductionReducer,
  SalesManageReducer,
  PurchaseSpendReducer,
  StatusInfoReducer,
  ChartReducer,
})
