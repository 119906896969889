import { handleActions } from "redux-actions"
import actions from "../actions"

// action type load
const { IS_LOGIN, SET_USER_INFO } = actions.SignAction

// default state value
const initialState = {
  _isLogin: false,
  _userInfo: {},
}

// handle action
export default handleActions(
  {
    [IS_LOGIN]: (state, action) => ({
      ...state,
      _isLogin: action.payload,
    }),
    [SET_USER_INFO]: (state, action) => ({
      ...state,
      _userInfo: action.payload,
    }),
  },
  initialState
)
