import { createAction } from "redux-actions"
import CommonApis from "../apis/CommonApis"

/* action type */
export const SET_SAMPLE = "@elefarm/statusInfo/SET_SAMPLE"
export const SET_INFO_PROFIT_AND_LOSS = "@elefarm/statusInfo/SET_INFO_PROFIT_AND_LOSS"
export const SET_SALES_CROP_LIST = "@elefarm/statusInfo/SET_SALES_CROP_LIST"
export const INIT_SENSOR_DATA_LIST_EXCEL = "@elefarm/systemSetting/INIT_SENSOR_DATA_LIST_EXCEL"
export const SET_SENSOR_DATA_LIST_EXCEL = "@elefarm/statusInfo/SET_SENSOR_DATA_LIST_EXCEL"

/* action function */
export const setSample = createAction(SET_SAMPLE)
export const setInfoProfitAndLoss = createAction(SET_INFO_PROFIT_AND_LOSS)
export const setSalesCropList = createAction(SET_SALES_CROP_LIST)
export const initSensorDataListExcel = createAction(INIT_SENSOR_DATA_LIST_EXCEL)
export const setSensorDataListExcel = createAction(SET_SENSOR_DATA_LIST_EXCEL)

/* action api function */
// 샘플
export const postSample = (param) => async (dispatch) => {
    const result = await CommonApis.postApi("", param)
    if (result.code === 0) {
        dispatch(setSample(true))
        return true
    } else return false
}

// 간편손익조회 리스트
export const getInfoProfitAndLoss = (param) => async (dispatch) => {
    const result = await CommonApis.getApi("/info/profitAndLoss/list", param)
    if (result.code === 0) {
        dispatch(setInfoProfitAndLoss(result.data.dataList))
        return true
    } else return false
}

// 작기별판매계획 작기 리스트
export const getSalesCropList = (param) => async (dispatch) => {
    const result = await CommonApis.getApi("/info/salsePlanPerformance/list", param)
    if (result.code === 0) {
        dispatch(setSalesCropList(result.data.salsePlanPerformanceList))
        return true
    } else return false
}

export const getSensorDataListExcel = (param) => async (dispatch) => {
    const result = await CommonApis.getApi("/info/sensorData/Grid/excel/download", param)
    if (result.code === 0) {
        dispatch(setSensorDataListExcel(result.data.dataList))
        return true
    } else return false
}

export const SENSOR_DATA_LIST = { uri: "/sensorData/list", id: "sensorDataList", initData: {} }
export const PEST_DATA_LIST = { uri: "/pestData/list", id: "pestDataList", initData: {} }
