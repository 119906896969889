import { createAction } from "redux-actions"
import CommonApis from "../apis/CommonApis"

/* action type */
export const SET_DATA_LIST = "@elefarm/admin/SET_DATA_LIST"

/* action function */
export const setDataList = createAction(SET_DATA_LIST)

export const initDataList = ( actionType ) => async (dispatch) => {

  let data = {}
  data[actionType.id] = actionType.initData

  dispatch(setDataList( data ))

  return true

}

export const getDataList = ( actionType, param, options = {} ) => async (dispatch) => {

  const result = await CommonApis.getApi( actionType.uri, param, options )

  if ( options.responseType === "blob" ) return result

  if (result.code === 0) {

    let data = {}
    data[actionType.id] = result.data.dataList

    dispatch(setDataList( data ))

    return true

  } else return false

}

// 다른서버로 api 요청하는 파트 센서데이터조회(표) 조회기능만 3001로 요청
// export const getDataList = ( actionType, param, options = {} ) => async (dispatch) => {

//   if (actionType.uri == "/info/sensorData/grid/list") {
//     const result = await CommonApis.getApis( actionType.uri, param, options )
//     console.log("123@@@@@@@@@@@@@@@@@@@@")
//     if ( options.responseType === "blob" ) return result
  
//     if (result.code === 0) {
  
//       let data = {}
//       data[actionType.id] = result.data.dataList
  
//       dispatch(setDataList( data ))
  
//       return true
  
//     } else return false
//   } else {
    
//     const result = await CommonApis.getApi( actionType.uri, param, options )
  
//     if ( options.responseType === "blob" ) return result
  
//     if (result.code === 0) {
  
//       let data = {}
//       data[actionType.id] = result.data.dataList
  
//       dispatch(setDataList( data ))
  
//       return true
  
//     } else return false
//   }


// }
export const saveDataList = ( actionType, param ) => async (dispatch) => {

  const result = await CommonApis.postApi( actionType.uri, param )

  if (result.code === 0) {

    return true

  } else return false

}

export const getComboList = (param) => async (dispatch) => {

  const result = await CommonApis.getApi("/system/combo/list", param)

  if (result.code === 0) {

    dispatch(setDataList( 
      
      param.queryKind === 'SMinor' ? { sminorComboList : result.data.dataList } : // 1. 공통코드
      param.queryKind === 'UMinor' ? { uminorComboList : result.data.dataList } : // 2. 사용자코드
      param.queryKind === 'WorkCenter' ? { workCenterComboList : result.data.dataList } : // 3. 작업장
      param.queryKind === 'Crop' ? { cropComboList : result.data.dataList } : // 4. 작기 
      param.queryKind === 'CropGrowth' ? { cropGrowthComboList : result.data.dataList } : // 5. ??
      param.queryKind === 'Farm' ? { farmComboList : result.data.dataList } : // 6. 농장
      param.queryKind === 'SensorNode' ? { sensorNodeComboList : result.data.dataList } : // 7. 센서노드
      param.queryKind === 'Sensor' ? { sensorComboList : result.data.dataList } : // 8. 센서 
      param.queryKind === 'Staff' ? { staffComboList : result.data.dataList } : // 9. 스태프 
      {}
    ))

    return true

  } else return false
}

export const getItemCodeHelpList = ( param ) => async (dispatch) => { // 품목  
  
  const result = await CommonApis.getApi( '/system/codehelp/item', param )

  if (result.code === 0) {
    
    dispatch(setDataList( { itemCodeHelpList : result.data.dataList } ))

    return true

  } else return false

}

export const getCustCodeHelpList = ( param ) => async (dispatch) => { // 거래처  
  
  const result = await CommonApis.getApi( '/system/codehelp/cust', param ) // custKind : 0(전체), 10060001(판매), 10060002(구매)

  if (result.code === 0) {
    
    dispatch(setDataList( { custCodeHelpList : result.data.dataList } ))

    return true

  } else return false

}

export const getCropCodeHelpList = ( param ) => async (dispatch) => { // 작기 - 차거담 2023.07.11
  
  const result = await CommonApis.getApi( '/system/codehelp/crop', param )

  if (result.code === 0) {
    
    dispatch(setDataList( { cropCodeHelpList : result.data.dataList } ))

    return true

  } else return false

}




// 다국어 TWord 초기화 - 차건담 2023.02.14
export const INIT_TRANSLATION_WORDS = "@elefarm/common/INIT_TRANSLATION_WORDS"
export const initTranslationWords = createAction(INIT_TRANSLATION_WORDS)

// 다국어 TWord 가져오기 - 차건담 2023.02.14
  export const SET_TRANSLATION_WORDS = "@elefarm/common/SET_TRANSLATION_WORDS"

  export const setTranslationWords = createAction(SET_TRANSLATION_WORDS)

  export const getTranslationWords = () => async (dispatch) => {
    const result = await CommonApis.getTranslationWords()
    console.log("commonAction result: ", result)
    if (result.code === 0) {    
      dispatch(setTranslationWords(result.data.TranslationWords))
      return true
    } else return false  
  }
