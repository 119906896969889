import { handleActions } from "redux-actions"
import actions from "../actions"

// action type load
const {
    INIT_AUTO_ENV_WORK_CENTER_LIST,
    SET_AUTO_ENV_WORK_CENTER_LIST,
    INIT_AUTO_ENV_EQUIP_LIST,
    SET_AUTO_ENV_EQUIP_LIST,
    INIT_AUTO_ENV_EQUIP,
    SET_AUTO_ENV_EQUIP,
    SET_SENSOR_DATA,

    INIT_AUTO_ENV_EQUIP_TIME,
    SET_AUTO_ENV_EQUIP_TIME,
    INIT_AUTO_ENV_CONF_EQUIP_ON_OFF,
    SET_AUTO_ENV_CONF_EQUIP_ON_OFF,
    INIT_AUTO_ENV_CONF_EQUIP_ON_OFF_EX,
    SET_AUTO_ENV_CONF_EQUIP_ON_OFF_EX,

    INIT_INTERLOCK_LIST,
    SET_INTERLOCK_LIST,
    INIT_INTERLOCK,
    SET_INTERLOCK,

    INIT_EQUIP_CONTROL_FORREV_LIST,
    SET_EQUIP_CONTROL_FORREV_LIST,
    INIT_EQUIP_CONTROL_ONOFF_LIST,
    SET_EQUIP_CONTROL_ONOFF_LIST,

    SET_EQUIP_CONTROL_STATUS,
    INIT_EQUIP_CONTROL_STATUS,

    //긴급경보알림
    INIT_EMERGENCYALARM_LIST,
    SET_EMERGENCYALARM_LIST,
    INIT_EMERGENCYALARM,
    SET_EMERGENCYALARM,

    //조건제어[복합]-목표값 조건변경
    SET_CONF_RESETTING_INFO,
} = actions.EnvControlAction

// default state value
const initialState = {
    _autoEnvWorkCenterList: [],
    _autoEnvEquipList: [],
    _autoEnvEquip: {},
    _sensorData: {},

    _autoEnvEquipTime: {},

    _autoEnvConfEquipOnOff: {},
    _autoEnvConfEquipOnOffEx: {},

    _interLockList: [],
    _interLock: {},

    _equipControlForRevList: [],
    _equipControlOnOffList: [],

    _equipControlStatus: {},

    _emergencyAlarmList: [],
    _emergencyAlarm: {},

    _confReSettingInfo: {},
}

// handle action
export default handleActions(
    {
        [INIT_AUTO_ENV_WORK_CENTER_LIST]: (state, action) => ({
            ...state,
            _autoEnvWorkCenterList: [],
        }),
        [SET_AUTO_ENV_WORK_CENTER_LIST]: (state, action) => ({
            ...state,
            _autoEnvWorkCenterList: action.payload,
        }),
        [INIT_AUTO_ENV_EQUIP_LIST]: (state, action) => ({
            ...state,
            _autoEnvEquipList: [],
        }),
        [SET_AUTO_ENV_EQUIP_LIST]: (state, action) => ({
            ...state,
            _autoEnvEquipList: action.payload,
        }),
        [INIT_AUTO_ENV_EQUIP]: (state, action) => ({
            ...state,
            _autoEnvEquip: [],
        }),
        [SET_AUTO_ENV_EQUIP]: (state, action) => ({
            ...state,
            _autoEnvEquip: action.payload,
        }),
        [SET_SENSOR_DATA]: (state, action) => ({
            ...state,
            _sensorData: action.payload,
        }),

        [INIT_AUTO_ENV_EQUIP_TIME]: (state, action) => ({
            ...state,
            _autoEnvEquipTime: {},
        }),
        [SET_AUTO_ENV_EQUIP_TIME]: (state, action) => ({
            ...state,
            _autoEnvEquipTime: action.payload,
        }),

        [INIT_AUTO_ENV_CONF_EQUIP_ON_OFF]: (state, action) => ({
            ...state,
            _autoEnvConfEquipOnOff: {},
        }),
        [SET_AUTO_ENV_CONF_EQUIP_ON_OFF]: (state, action) => ({
            ...state,
            _autoEnvConfEquipOnOff: action.payload,
        }),
        [INIT_AUTO_ENV_CONF_EQUIP_ON_OFF_EX]: (state, action) => ({
            ...state,
            _autoEnvConfEquipOnOffEx: {},
        }),
        [SET_AUTO_ENV_CONF_EQUIP_ON_OFF_EX]: (state, action) => ({
            ...state,
            _autoEnvConfEquipOnOffEx: action.payload,
        }),

        [INIT_INTERLOCK_LIST]: (state, action) => ({
            ...state,
            _interLockList: [],
        }),
        [SET_INTERLOCK_LIST]: (state, action) => ({
            ...state,
            _interLockList: action.payload,
        }),
        [INIT_INTERLOCK]: (state, action) => ({
            ...state,
            _interLock: {},
        }),
        [SET_INTERLOCK]: (state, action) => ({
            ...state,
            _interLock: action.payload,
        }),

        //긴급경보알림
        [INIT_EMERGENCYALARM_LIST]: (state, action) => ({
            ...state,
            _emergencyAlarmList: [],
        }),
        [SET_EMERGENCYALARM_LIST]: (state, action) => ({
            ...state,
            _emergencyAlarmList: action.payload,
        }),
        [INIT_EMERGENCYALARM]: (state, action) => ({
            ...state,
            _emergencyAlarm: {},
        }),
        [SET_EMERGENCYALARM]: (state, action) => ({
            ...state,
            _emergencyAlarm: action.payload,
        }),

        //조건제어[복합] - 목표값 조건변경
        [SET_CONF_RESETTING_INFO]: (state, action) => ({
            ...state,
            _confReSettingInfo: action.payload,
        }),

        [INIT_EQUIP_CONTROL_FORREV_LIST]: (state, action) => ({
            ...state,
            _equipControlForRevList: [],
        }),
        [SET_EQUIP_CONTROL_FORREV_LIST]: (state, action) => ({
            ...state,
            _equipControlForRevList: action.payload,
        }),
        [INIT_EQUIP_CONTROL_ONOFF_LIST]: (state, action) => ({
            ...state,
            _equipControlOnOffList: [],
        }),
        [SET_EQUIP_CONTROL_ONOFF_LIST]: (state, action) => ({
            ...state,
            _equipControlOnOffList: action.payload,
        }),
        [SET_EQUIP_CONTROL_STATUS]: (state, action) => ({
            ...state,
            _equipControlStatus: action.payload,
        }),
        [INIT_EQUIP_CONTROL_STATUS]: (state, action) => ({
            ...state,
            _equipControlStatus: {},
        }),
    },

    initialState
)
