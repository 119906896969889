import { createAction } from "redux-actions"
import DefaultInfoApis from "../apis/DefaultInfoApis"
import CommonApis from "../apis/CommonApis"

/* action type */
export const SET_FARM_INFO = "@elefarm/defaultInfo/SET_FARM_INFO"

export const SET_USER_LIST = "@elefarm/defaultInfo/SET_USER_LIST"
export const INIT_USER = "@elefarm/defaultInfo/INIT_USER"
export const SET_USER = "@elefarm/defaultInfo/SET_USER"

export const INIT_ITEM_LIST = "@elefarm/defaultInfo/INIT_ITEM_LIST"
export const SET_ITEM_LIST = "@elefarm/defaultInfo/SET_ITEM_LIST"
export const INIT_ITEM = "@elefarm/defaultInfo/INIT_ITEM"
export const SET_ITEM = "@elefarm/defaultInfo/SET_ITEM"

export const INIT_CUSTOMER_LIST = "@elefarm/defaultInfo/INIT_CUSTOMER_LIST"
export const SET_CUSTOMER_LIST = "@elefarm/defaultInfo/SET_CUSTOMER_LIST"
export const INIT_CUSTOMER = "@elefarm/defaultInfo/INIT_CUSTOMER"
export const SET_CUSTOMER = "@elefarm/defaultInfo/SET_CUSTOMER"

export const INIT_WORK_CENTER_LIST = "@elefarm/defaultInfo/INIT_WORK_CENTER_LIST"
export const SET_WORK_CENTER_LIST = "@elefarm/defaultInfo/SET_WORK_CENTER_LIST"
export const INIT_WORK_CENTER = "@elefarm/defaultInfo/INIT_WORK_CENTER"
export const SET_WORK_CENTER = "@elefarm/defaultInfo/SET_WORK_CENTER"

export const INIT_GATE_WAY_LIST = "@elefarm/defaultInfo/INIT_GATE_WAY_LIST"
export const SET_GATE_WAY_LIST = "@elefarm/defaultInfo/SET_GATE_WAY_LIST"
export const INIT_GATE_WAY = "@elefarm/defaultInfo/INIT_GATE_WAY"
export const SET_GATE_WAY = "@elefarm/defaultInfo/SET_GATE_WAY"

export const INIT_SENSOR_NODE_LIST = "@elefarm/defaultInfo/INIT_SENSOR_NODE_LIST"
export const SET_SENSOR_NODE_LIST = "@elefarm/defaultInfo/SET_SENSOR_NODE_LIST"
export const INIT_SENSOR_NODE = "@elefarm/defaultInfo/INIT_SENSOR_NODE"
export const SET_SENSOR_NODE = "@elefarm/defaultInfo/SET_SENSOR_NODE"
export const INIT_SENSOR_LIST = "@elefarm/defaultInfo/INIT_SENSOR_LIST"
export const SET_SENSOR_LIST = "@elefarm/defaultInfo/SET_SENSOR_LIST"
export const INIT_SENSOR = "@elefarm/defaultInfo/INIT_SENSOR"
export const SET_SENSOR = "@elefarm/defaultInfo/SET_SENSOR"

export const INIT_CONTROL_NODE_LIST = "@elefarm/defaultInfo/INIT_CONTROL_NODE_LIST"
export const SET_CONTROL_NODE_LIST = "@elefarm/defaultInfo/SET_CONTROL_NODE_LIST"
export const INIT_CONTROL_NODE = "@elefarm/defaultInfo/INIT_CONTROL_NODE"
export const SET_CONTROL_NODE = "@elefarm/defaultInfo/SET_CONTROL_NODE"

export const INIT_PRODUCTION_LINE_LIST = "@elefarm/defaultInfo/INIT_PRODUCTION_LINE_LIST"
export const SET_PRODUCTION_LINE_LIST = "@elefarm/defaultInfo/SET_PRODUCTION_LINE_LIST"
export const INIT_PRODUCTION_LINE = "@elefarm/defaultInfo/INIT_PRODUCTION_LINE"
export const SET_PRODUCTION_LINE = "@elefarm/defaultInfo/SET_PRODUCTION_LINE"

export const INIT_PRODUCTION_LINE_LIST_DIV = "@elefarm/defaultInfo/INIT_PRODUCTION_LINE_LIST_DIV"
export const SET_PRODUCTION_LINE_LIST_DIV = "@elefarm/defaultInfo/SET_PRODUCTION_LINE_LIST_DIV"

/* action function */
export const setFarmInfo = createAction(SET_FARM_INFO)

export const setUserList = createAction(SET_USER_LIST)
export const initUser = createAction(INIT_USER)
export const setUser = createAction(SET_USER)

export const initItemList = createAction(INIT_ITEM_LIST)
export const setItemList = createAction(SET_ITEM_LIST)
export const initItem = createAction(INIT_ITEM)
export const setItem = createAction(SET_ITEM)

export const initCustomerList = createAction(INIT_CUSTOMER_LIST)
export const setCustomerList = createAction(SET_CUSTOMER_LIST)
export const initCustomer = createAction(INIT_CUSTOMER)
export const setCustomer = createAction(SET_CUSTOMER)

export const initWorkCenterList = createAction(INIT_WORK_CENTER_LIST)
export const setWorkCenterList = createAction(SET_WORK_CENTER_LIST)
export const initWorkCenter = createAction(INIT_WORK_CENTER)
export const setWorkCenter = createAction(SET_WORK_CENTER)

export const initGateWayList = createAction(INIT_GATE_WAY_LIST)
export const setGateWayList = createAction(SET_GATE_WAY_LIST)
export const initGateWay = createAction(INIT_GATE_WAY)
export const setGateWay = createAction(SET_GATE_WAY)

export const initSensorNodeList = createAction(INIT_SENSOR_NODE_LIST)
export const setSensorNodeList = createAction(SET_SENSOR_NODE_LIST)
export const initSensorNode = createAction(INIT_SENSOR_NODE)
export const setSensorNode = createAction(SET_SENSOR_NODE)
export const initSensorList = createAction(INIT_SENSOR_LIST)
export const setSensorList = createAction(SET_SENSOR_LIST)
export const initSensor = createAction(INIT_SENSOR)
export const setSensor = createAction(SET_SENSOR)

export const initControlNodeList = createAction(INIT_CONTROL_NODE_LIST)
export const setControlNodeList = createAction(SET_CONTROL_NODE_LIST)
export const initControlNode = createAction(INIT_CONTROL_NODE)
export const setControlNode = createAction(SET_CONTROL_NODE)

export const initProductionLineList = createAction(INIT_PRODUCTION_LINE_LIST)
export const setProductionLineList = createAction(SET_PRODUCTION_LINE_LIST)
export const initProductionLine = createAction(INIT_PRODUCTION_LINE)
export const setProductionLine = createAction(SET_PRODUCTION_LINE)

export const initProductionLineListDiv = createAction(INIT_PRODUCTION_LINE_LIST_DIV)
export const setProductionLineListDiv = createAction(SET_PRODUCTION_LINE_LIST_DIV)

/* action api function */
// 농장 정보
export const getFarmInfo = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.getFarmInfo(param)
  if (result.code === 0) {
    dispatch(setFarmInfo(result.data.farmInfo))
    return true
  } else return false
}
// 농장 정보 수정
export const editFarmInfo = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.editFarmInfo(param)
  if (result.code === 0) {
    return true
  } else return false
}

// 사용자 리스트
export const getUserList = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.getUserList(param)
  if (result.code === 0) {
    dispatch(setUserList(result.data.userList))
    return true
  } else return false
}
// 사용자 추가
export const addUser = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.addUser(param)
  if (result.code === 0) {
    return true
  } else return result
}
// 사용자 정보
export const getUser = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.getUser(param)
  if (result.code === 0) {
    dispatch(setUser(result.data.userInfo))
    return true
  } else return false
}
// 사용자 수정
export const editUser = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.editUser(param)
  if (result.code === 0) {
    return true
  } else return result
}
// 사용자 삭제
export const delUser = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.delUser(param)
  if (result.code === 0) {
    return true
  } else return false
}
// 사용자 리스트 엑셀 다운로드
export const downloadUserListExcel = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.downloadUserListExcel(param)
  return result
}

// 품목 리스트
export const getItemList = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.getItemList(param)
  if (result.code === 0) {
    dispatch(setItemList(result.data.itemList))
    return true
  } else return false
}
// 품목 추가
export const addItem = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.addItem(param)
  if (result.code === 0) {
    return true
  } else return result
}
// 품목 정보
export const getItem = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.getItem(param)
  if (result.code === 0) {
    dispatch(setItem(result.data.itemInfo))
    return true
  } else return false
}
// 품목 수정
export const editItem = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.editItem(param)
  if (result.code === 0) {
    return true
  } else return result
}
// 품목 삭제
export const delItem = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.delItem(param)
  if (result.code === 0) {
    return true
  } else return false
}
// 품목 리스트 엑셀 다운로드
export const downloadItemListExcel = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.downloadItemListExcel(param)
  return result
}

// 거래처 리스트
export const getCustomerList = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.getCustomerList(param)
  if (result.code === 0) {
    dispatch(setCustomerList(result.data.custList))
    return true
  } else return false
}
// 거래처 추가
export const addCustomer = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.addCustomer(param)
  if (result.code === 0) {
    return true
  } else return result
}
// 거래처 정보
export const getCustomer = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.getCustomer(param)
  if (result.code === 0) {
    dispatch(setCustomer(result.data.custInfo[0]))
    return true
  } else return false
}
// 거래처 수정
export const editCustomer = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.editCustomer(param)
  if (result.code === 0) {
    return true
  } else return result
}
// 거래처 삭제
export const delCustomer = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.delCustomer(param)
  if (result.code === 0) {
    return true
  } else return false
}
// 거래처 리스트 엑셀 다운로드
export const downloadCustomerListExcel = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.downloadCustomerListExcel(param)
  return result
}

// 작업장 리스트
export const getWorkCenterList = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.getWorkCenterList(param)
  if (result.code === 0) {
    dispatch(setWorkCenterList(result.data.workCenterList))
    return true
  } else return false
}
// 작업장 추가
export const addWorkCenter = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.addWorkCenter(param)
  if (result.code === 0) {
    return true
  } else return result
}
// 작업장 정보
export const getWorkCenter = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.getWorkCenter(param)
  if (result.code === 0) {
    dispatch(setWorkCenter(result.data.workCenterInfo[0]))
    return true
  } else return false
}
// 작업장 수정
export const editWorkCenter = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.editWorkCenter(param)
  if (result.code === 0) {
    return true
  } else return result
}
// 작업장 삭제
export const delWorkCenter = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.delWorkCenter(param)
  if (result.code === 0) {
    return true
  } else return false
}
// 작업장 리스트 엑셀 다운로드
export const downloadWorkCenterListExcel = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.downloadWorkCenterListExcel(param)
  return result
}

// 게이트웨이 리스트
export const getGateWayList = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.getGateWayList(param)
  if (result.code === 0) {
    dispatch(setGateWayList(result.data.gatewayList))
    return true
  } else return false
}
// 게이트웨이 추가
export const addGateWay = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.addGateWay(param)
  if (result.code === 0) {
    return true
  } else return result
}
// 게이트웨이 정보
export const getGateWay = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.getGateWay(param)
  if (result.code === 0) {
    dispatch(setGateWay(result.data.gatewayInfo))
    return true
  } else return false
}
// 게이트웨이 수정
export const editGateWay = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.editGateWay(param)
  if (result.code === 0) {
    return true
  } else return result
}
// 게이트웨이 삭제
export const delGateWay = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.delGateWay(param)
  if (result.code === 0) {
    return true
  } else return false
}
// 게이트웨이 리스트 엑셀 다운로드
export const downloadGateWayListExcel = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.downloadGateWayListExcel(param)
  return result
}

// 센서노드 리스트
export const getSensorNodeList = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.getSensorNodeList(param)
  if (result.code === 0) {
    dispatch(setSensorNodeList(result.data.sensorNodeList))
    return true
  } else return false
}
// 센서노드 추가
export const addSensorNode = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.addSensorNode(param)
  if (result.code === 0) {
    return true
  } else return result
}
// 센서노드 정보
export const getSensorNode = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.getSensorNode(param)
  if (result.code === 0) {
    dispatch(setSensorNode(result.data.sensorNodeInfo))
    return true
  } else return false
}
// 센서노드 수정
export const editSensorNode = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.editSensorNode(param)
  if (result.code === 0) {
    return true
  } else return result
}
// 센서노드 삭제
export const delSensorNode = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.delSensorNode(param)
  if (result.code === 0) {
    return true
  } else return false
}
// 센서노드 리스트 엑셀 다운로드
export const downloadSensorNodeListExcel = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.downloadSensorNodeListExcel(param)
  return result
}
// 센서 리스트
export const getSensorList = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.getSensorList(param)
  if (result.code === 0) {
    dispatch(setSensorList(result.data.sensorList))
    return true
  } else return false
}
// 센서 추가
export const addSensor = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.addSensor(param)
  if (result.code === 0) {
    return true
  } else return result
}
// 센서 정보
export const getSensor = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.getSensor(param)
  if (result.code === 0) {
    dispatch(setSensor(result.data.sensorInfo))
    return true
  } else return false
}
// 센서 수정
export const editSensor = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.editSensor(param)
  if (result.code === 0) {
    return true
  } else return result
}
// 센서 삭제
export const delSensor = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.delSensor(param)
  if (result.code === 0) {
    return true
  } else return false
}

// 제어노드 리스트
export const getControlNodeList = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.getControlNodeList(param)
  if (result.code === 0) {
    dispatch(setControlNodeList(result.data.controlNodeList))
    return true
  } else return false
}
// 제어노드 추가
export const addControlNode = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.addControlNode(param)
  if (result.code === 0) {
    return true
  } else return result
}
// 제어노드 정보
export const getControlNode = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.getControlNode(param)
  if (result.code === 0) {
    dispatch(setControlNode(result.data.controlNodeInfo))
    return true
  } else return false
}
// 제어노드 수정
export const editControlNode = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.editControlNode(param)
  if (result.code === 0) {
    return true
  } else return result
}
// 제어노드 삭제
export const delControlNode = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.delControlNode(param)
  if (result.code === 0) {
    return true
  } else return false
}
// 제어노드 리스트 엑셀 다운로드
export const downloadControlNodeListExcel = (param) => async (dispatch) => {
  const result = await DefaultInfoApis.downloadControlNodeListExcel(param)
  return result
}

// 생산설비 리스트
export const getProductionLineList = (param) => async (dispatch) => {
  const result = await CommonApis.getApi(`/info/equip/list`, param)
  if (result.code === 0) {
    dispatch(setProductionLineList(result.data.equipList))
    return true
  } else return false
}
// 생산설비 리스트(확인용)
export const getProductionLineListDiv = (param) => async (dispatch) => {
  const result = await CommonApis.getApi(`/info/equip/list`, param)
  if (result.code === 0) {
    dispatch(setProductionLineListDiv(result.data.equipList))
    return true
  } else return false
}
// 생산설비 추가
export const addProductionLine = (param) => async (dispatch) => {
  const result = await CommonApis.postApi(`/info/equip/add`, param)
  if (result.code === 0) {
    return true
  } else return result
}
// 생산설비 정보
export const getProductionLine = (param) => async (dispatch) => {
  const result = await CommonApis.getApi(`/info/equip/info`, param)
  if (result.code === 0) {
    dispatch(setProductionLine(result.data.equipInfo))
    return true
  } else return false
}
// 생산설비 수정
export const editProductionLine = (param) => async (dispatch) => {
  const result = await CommonApis.postApi(`/info/equip/mod`, param)
  if (result.code === 0) {
    return true
  } else return result
}
// 생산설비 삭제
export const delProductionLine = (param) => async (dispatch) => {
  const result = await CommonApis.postApi(`/info/equip/del`, param)
  if (result.code === 0) {
    return true
  } else return false
}
// 생산설비 리스트 엑셀 다운로드
export const downloadProductionLineListExcel = (param) => async (dispatch) => {
  const result = await CommonApis.getApi(`/info/equip/excel/download`, param, { responseType: "blob" })
  return result
}
