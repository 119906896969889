import { addProductionLine } from "../actions/DefaultInfoAction"
import api from "../utils/api"

// 모듈 리스트
const getModuleList = async (data) => {
  const result = await api.get(`/system/module/list`)
  return result.data
}
// 메뉴 리스트
const getMenuList = async (data) => {
  const result = await api.get(`/system/menu/list?moduleSeq=${data.moduleSeq}`)
  return result.data
}
// 코드분류 리스트
const getCodeList = async (data) => {
  const result = await api.get(`/system/pgm/minor/list?pgmSeq=${data.pgmSeq}`)
  return result.data
}
// 코드도움 - 품목
const getCodeHelpItem = async (data) => {
  const result = await api.get(`/system/codehelp/item?queryWord=${data.queryWord}`)
  return result.data
}
// 코드도움 - 거래처
const getCodeHelpCustomer = async (data) => {
  const result = await api.get(`/system/codehelp/cust?queryWord=${data.queryWord}&custKind=${data.custKind}`)
  return result.data
}
// 콤보 (WorkCenter, Gateway, ControlNode)
const getComboList = async (data) => {
  const result = await api.get(`/system/combo/list?queryKind=${data.queryKind}&cropSeq=${data.cropSeq || 0}`)
  return result.data
}

// 환경설정 정보
const getEnvSettingInfo = async (data) => {
  const result = await api.get(`/info/setting/info`)
  return result.data
}
// 환경설정 정보 저장
const editEnvSettingInfo = async (data) => {
  const result = await api.post(`/info/setting/mod`, { body: data })
  return result.data
}
// 사용자코드(대분류조회)
const getUserMajorCode = async (data) => {
  //const result = await api.get(`/system/TUMajor/list?majorName=${data.majorName}`)
  const result = await api.get(`/system/TUMajor/list?majorName=${data.majorName}`)
  return result.data
}
// 사용자코드(소분류조회)
const getUserMinorCode = async (data) => {
  const result = await api.get(`/system/TUMinor/list?majorSeq=${data.majorSeq}`)
  return result.data
}
// 사용자코드(소분류추가)
const addUserMinorCode = async (data) => {
  const result = await api.post(`/system/TUMinor/add`, { body: data })
  return result.data
}
// 사용자코드(소분류수정)
const editUserMinorCode = async (data) => {
  const result = await api.post(`/system/TUMinor/mod`, { body: data })
  return result.data
}
// 사용자코드(소분류삭제)
const delUserMinorCode = async (data) => {
  const result = await api.post(`/system/TUMinor/del`, { body: data })
  return result.data
}

// 언어종류
const getLanguageKind = async () => {
  const result = await api.get(`/system/TSMinor/languageKind`)
  return result.data
}

// 시스템코드 - 차건담 2023.01.20 ================================================
  // 사용자코드(대분류조회)
  const getSysMajorCode = async (data) => {
    const result = await api.get(`/system/TSMajor/list?majorName=${data.majorName}`)
    return result.data
  }
  // 사용자코드(소분류조회)
  const getSysMinorCode = async (data) => {
    const result = await api.get(`/system/TSMinor/list?majorSeq=${data.majorSeq}`)
    return result.data
  }

// ==============================================================================


// 다국어========================================================================
  // 사용자설정언어 (사용자설정 대분류, 시스템 대분류, 시스템 소분류 언어조회) 차건담 - 2023.01.30
  const getUMajorSMajorSMinor = async (data) => {
    const result = await api.get(`/system/TSDLauguage/list?majorSeq=${data.majorSeq}&&minorSeq=${data.minorSeq}&&checkSysUser=${data.checkSysUser}`)
    return result.data
  }

  // 사용자설정언어 (사용자설정 소분류 언어조회, 유용준)
  const getUDLanguageCode = async (data) => {
    const result = await api.get(`/system/TUDLauguage/list?minorSeq=${data.minorSeq}&&majorSeq=${null}`)
    return result.data
  }
  // 사용자설정언어 (사용자설정 소분류 언어수정, 유용준)
  const editUDLanguageCode = async (data) => {
    const result = await api.post(`/system/TUDLanguage/mod`, { body: data })
    return result.data
  }

  // 사용자설정언어 (사용자설정 소분류 언어추가) - 차건담 2023.02.09
  const insertUDLanguageCode = async (data) => {
    const result = await api.post(`/system/TUDLanguage/insert`, { body: data })
    return result.data
  }

  // 사용자설정언어 (사용자설정 소분류 언어삭제) - 차건담 2023.02.13
  const delUDLanguageCode = async (data) => {
    const result = await api.post(`/system/TUDLanguage/del`, { body: data })
    return result.data
  }  

  // 시스템 다국어 조회 - 차건담 2023.01.20
  const getSDLanguageCode = async (data) => {
    const result = await api.get(`/system/TSDLauguage/majorSeq=${data.majorSeq}&&minorSeq=${data.minorSeq}`)
    return result.data
  }
  // 사용자 대분류, 시스템 소분류/대분류 다국어 수정 - 차건담 2023.01.20
  const editUMajorSMajorSMinor = async (data) => {
    const result = await api.post(`/system/TSDLanguage/mod`, { body: data })
    return result.data
  }
  // 사용자 대분류, 시스템 소분류/대분류 다국어 수정 - 차건담 2023.02.07
  const insertUMajorSMajorSMinor = async (data) => {
    const result = await api.post(`/system/TSDLanguage/insert`, { body: data })
    return result.data
  }
// ==============================================================================

// 센서데이터 조회================================================================
  // 차트데이터 가져오기 - 차건담 2023.05.11
  const getChartData = async (data) => {
    //const result = await api.get(`/sensorData/chart/list?workCenterSeq=${data.workCenterSeq}`)
    const result = await api.get(`/sensorData/chart/list?workCenterSeq=${data.workCenterSeq}&&sensorNodeSeq=${data.sensorNodeSeq}&&sensorKind=${data.sensorKind}&&unitInquiry=${data.unitInquiry}&&dateFr=${data.dateFr}&&dateTo=${data.dateTo}`)
    return result.data
  }

  const getCompareChartData = async (data) => {
    //const result = await api.get(`/sensorData/chart/list?workCenterSeq=${data.workCenterSeq}`)
    const result = await api.get(`/sensorData/compareChart/list?workCenterSeq=${data.workCenterSeq}&&sensorNodeSeq=${data.sensorNodeSeq}&&sensorKind=${data.sensorKind}&&unitInquiry=${data.unitInquiry}&&dateFr=${data.dateFr}&&dateTo=${data.dateTo}`)
    return result.data
  }

  const getChartComboList = async (data) => {
    const result = await api.get(`/system/combo/list`, { body: data })
    return result.data
  }

  // 기간내 센서데이터 최소값 최대값 가져오기 - 차건담 2023.05.24
  const getSensorMinMax = async (data) => {
    const result = await api.get(`/sensorData/minmax/list?workCenterSeq=${data.workCenterSeq}&&sensorNodeSeq=${data.sensorNodeSeq}&&sensorKind=${data.sensorKind}&&unitInquiry=${data.unitInquiry}&&dateFr=${data.dateFr}&&dateTo=${data.dateTo}&&tab=${data.tab}`)
    return result.data
  } 

  // 센서 목표값 가져오기 - 차건담 2023.06.12
  const getTargetValueList = async (data) => {
    const result = await api.get(`/sensorData/targetValue/list?sensorKind=${data.sensorKind}`)
    return result.data
  }   

  // 센서 목표값 저장하기 - 차건담 2023.06.13
  const insertTargetValueList = async (data) => {
    const result = await api.post(`/sensorData/targetValue/insert`, { body: data })
    return result.data
  }
// ==============================================================================


// 모듈권한 리스트
const getModuleAuthList = async (data) => {
  const result = await api.get(`/system/userModuleAuth/list?word=${data.word}`)
  return result.data
}
// 모듈권한 수정
const editModuleAuth = async (data) => {
  const result = await api.post(`/system/userModuleAuth/mod`, { body: data })
  return result.data
}
// 프로그램권한 리스트(모듈,메뉴)
const getUserPgmAuthMenuList = async (data) => {
  const result = await api.get(`/system/userPgmAuth/menu/list`)
  return result.data
}
// 프로그램권한 리스트(프로그램)
const getUserPgmAuthList = async (data) => {
  const result = await api.get(`/system/userPgmAuth/list?menuSeq=${data.menuSeq}&userName=${data.userName}&pgmName=${data.pgmName}`)
  return result.data
}
// 프로그램권한 수정
const editUserPgmAuth = async (data) => {
  const result = await api.post(`/system/userPgmAuth/mod`, { body: data })
  return result.data
}

export default {
  getModuleList,
  getMenuList,
  getCodeList,
  getCodeHelpItem,
  getCodeHelpCustomer,
  getComboList,

  getEnvSettingInfo,
  editEnvSettingInfo,
  getUserMajorCode,
  getUserMinorCode,
  addUserMinorCode,
  editUserMinorCode,
  delUserMinorCode,
  getModuleAuthList,
  editModuleAuth,
  getUserPgmAuthMenuList,
  getUserPgmAuthList,
  editUserPgmAuth,
  // 사용자 다국어 관련
    // 대분류 - 차건담 2023.01.30
    getUMajorSMajorSMinor,
    // 소분류 - 유용준
    getUDLanguageCode,
    editUDLanguageCode,
    insertUDLanguageCode, // 차건담 2023.02.09
    delUDLanguageCode, // 차건담 2023.02.13

  // 시스템코드 관련 - 차건담 2023.01.20
  getSysMajorCode,
  getSysMinorCode,

  // 시스템 다국어 관련 - 차건담 2023.01.20
  getSDLanguageCode,

  // 다국어 공통[사용자 대분류, 시스템 대분류/소분류] - 차건담 2023.01.20
  editUMajorSMajorSMinor,
  insertUMajorSMajorSMinor, // 2023.02.07
  
  // 언어종류 가져오기 - 차건담 2023.01.30
  getLanguageKind,

  // 센서데이터 ==================================================
    // 센서데이터 조회 차트 데이터 가져오기 - 차건담 2023.05.11
    getChartData,

    // 센서데이터 조회 비교 차트 데이터 가져오기 - 차건담 2023.06.01
    getCompareChartData,
    
    // 센서데이터 콤보리스트 가져오기 - 차건담 2023.05.12
    getChartComboList,

    // 기간내 센서데이터 최소값 최대값 가져오기 - 차건담 2023.05.24
    getSensorMinMax,

    // 센서 목표값 가져오기 - 차건담 2023.06.12
    getTargetValueList,

    // 센서 목표값 저장하기 - 차건담 2023.06.12
    insertTargetValueList
  // ============================================================
}