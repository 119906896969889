import querystring from "querystring"
import api from "../utils/api"

const getTranslationWords = async (data) => {
    // console.log("API 진입 전")
    const result = await api.get(`/system/TWord/translate`)
    // console.log("API 진입 후:", result)
    return result.data
}

export default {
    getApi: async (uri, data, options = {}) => {
        Object.keys(data || {}).forEach((key) => {
            const _ = data[key]

            if (Array.isArray(_)) data[key] = JSON.stringify(data[key])
        })

        let queryString = querystring.stringify(data)
        const result = await api.get(`${uri}?${queryString}`, { options: options })

        return result.data
    },

    // 다른서버로 api 요청하는 파트
    // getApis: async (uri, data, options = {}) => {
    //   Object.keys(data || {}).forEach((key) => {
    //     const _ = data[key]

    //     if (Array.isArray(_)) data[key] = JSON.stringify(data[key])
    //   })

    //   let queryString = querystring.stringify(data)
    //   const result = await api.gets(`${uri}?${queryString}`, { options: options })

    //   return result.data
    // },
    postApi: async (uri, data) => {
        const result = await api.post(uri, { body: data })

        return result.data
    },

    getTranslationWords,
}
