import { createAction } from "redux-actions"
import CommonApis from "../apis/CommonApis"

/* action type */
export const SET_SALES_CROP_LIST = "@elefarm/salesManage/SET_SALES_CROP_LIST"
export const SET_SALES_CROP_PLAN_LIST = "@elefarm/salesManage/SET_SALES_CROP_PLAN_LIST"
export const SET_SALES_CROP_PLAN = "@elefarm/salesManage/SET_SALES_CROP_PLAN"

/* action function */
export const setSalesCropList = createAction(SET_SALES_CROP_LIST)
export const setSalesCropPlanList = createAction(SET_SALES_CROP_PLAN_LIST)
export const setSalesCropPlan = createAction(SET_SALES_CROP_PLAN)

/* action api function */
// 작기별판매계획 작기 리스트
export const getSalesCropList = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/sales/crop/list", param)
  if (result.code === 0) {
    dispatch(setSalesCropList(result.data.cropList))
    return true
  } else return false
}
// 작기별판매계획 판매계획(월별) 리스트
export const getSalesCropPlanList = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/sales/crop/salesPlan/list", param)
  if (result.code === 0) {
    dispatch(setSalesCropPlanList(result.data.cropSalesPlanList))
    return true
  } else return false
}
// 작기별판매계획 판매계획(월별) 추가
export const addSalesCropPlan = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/sales/crop/salesPlan/add", param)
  if (result.code === 0) {
    return true
  } else return false
}
// 작기별판매계획 판매계획(월별) 정보
export const getSalesCropPlan = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/sales/crop/salesPlan/info", param)
  if (result.code === 0) {
    dispatch(setSalesCropPlan(result.data.cropSalesPlanInfo))
    return true
  } else return false
}
// 작기별판매계획 판매계획(월별) 정보 수정
export const editSalesCropPlan = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/sales/crop/salesPlan/mod", param)
  if (result.code === 0) {
    return true
  } else return false
}
// 작기별판매계획 판매계획(월별) 삭제
export const delSalesCropPlan = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/sales/crop/salesPlan/del", param)
  if (result.code === 0) {
    return true
  } else return false
}

export const SALES_LIST = { uri : '/sales/list', id : 'salesList', initData : [] }
export const SALES_INFO = { uri : '/sales/info', id : 'salesInfo', initData : {} }

export const ADD_SALES_INFO = { uri : '/sales/add', id : '', initData : {} }
export const MOD_SALES_INFO = { uri : '/sales/mod', id : '', initData : {} }
export const DEL_SALES_INFO = { uri : '/sales/del', id : '', initData : {} }
export const DEL_SALES_ITEM = { uri : '/sales/item/del', id : '', initData : {} }

export const SALES_OUT_LIST = { uri : '/salesOut/list', id : 'salesOutList', initData : [] }
export const SALES_OUT_EXCEL = { uri : '/salesOut/excel/download', id : '', initData : [] }

export const ADD_SALES_OUT_INFO = { uri : '/salesOut/add', id : '', initData : {} }
export const MOD_SALES_OUT_INFO = { uri : '/salesOut/mod', id : '', initData : {} }
export const DEL_SALES_OUT_INFO = { uri : '/salesOut/del', id : '', initData : {} }

export const OUT_BARCODE_SALES_LIST = { uri : '/outBarcode/salesOut/list', id : 'outBarcodeSalesList', initData : [] }
export const OUT_BARCODE_LIST = { uri : '/outBarcode/list', id : 'outBarcodeList', initData : [] }

export const ADD_OUT_BARCODE_INFO = { uri : '/outBarcode/add', id : '', initData : {} }
export const DEL_OUT_BARCODE_INFO = { uri : '/outBarcode/del', id : '', initData : {} }

