import { createAction } from "redux-actions"
import CommonApis from "../apis/CommonApis"
import SystemSettingApis from "../apis/SystemSettingApis"
import { TOKEN_ID } from "config"

/* action type */
export const SET_MODULE_LIST = "@elefarm/systemSetting/SET_MODULE_LIST"
export const SET_MENU_LIST = "@elefarm/systemSetting/SET_MENU_LIST"
export const SET_CODE_LIST = "@elefarm/systemSetting/SET_CODE_LIST"
export const SET_CODE_HELP_ITEM = "@elefarm/systemSetting/SET_CODE_HELP_ITEM"
export const SET_CODE_HELP_CUSTOMER = "@elefarm/systemSetting/SET_CODE_HELP_CUSTOMER"
export const SET_COMBO_WORK_CENTER_LIST = "@elefarm/systemSetting/SET_COMBO_WORK_CENTER_LIST"
export const SET_COMBO_GATEWAY_LIST = "@elefarm/systemSetting/SET_COMBO_GATEWAY_LIST"
export const SET_COMBO_CONTROL_NODE_LIST = "@elefarm/systemSetting/SET_COMBO_CONTROL_NODE_LIST"
export const SET_COMBO_EQUIP_LIST = "@elefarm/systemSetting/SET_COMBO_EQUIP_LIST"
export const SET_COMBO_STDCROP_LIST = "@elefarm/systemSetting/SET_COMBO_STDCROP_LIST"
export const SET_COMBO_CROP_LIST = "@elefarm/systemSetting/SET_COMBO_CROP_LIST"
export const SET_COMBO_CROP_GROWTH_LIST = "@elefarm/systemSetting/SET_COMBO_CROP_GROWTH_LIST"
export const SET_BOOK_MARK_LIST = "@elefarm/systemSetting/SET_BOOK_MARK_LIST"

export const INIT_USER_MAJOR_CODE = "@elefarm/systemSetting/INIT_USER_MAJOR_CODE"
export const SET_USER_MAJOR_CODE = "@elefarm/systemSetting/SET_USER_MAJOR_CODE"
export const INIT_USER_MINOR_CODE = "@elefarm/systemSetting/INIT_USER_MINOR_CODE"
export const SET_USER_MINOR_CODE = "@elefarm/systemSetting/SET_USER_MINOR_CODE"
export const SET_ENV_SETTING_INFO = "@elefarm/systemSetting/SET_ENV_SETTING_INFO"
export const SET_MODULE_AUTH_LIST = "@elefarm/systemSetting/SET_MODULE_AUTH_LIST"
export const INIT_USER_PGM_AUTH_MENU_LIST = "@elefarm/systemSetting/INIT_USER_PGM_AUTH_MENU_LIST"
export const SET_USER_PGM_AUTH_MENU_LIST = "@elefarm/systemSetting/SET_USER_PGM_AUTH_MENU_LIST"
export const INIT_USER_PGM_AUTH_LIST = "@elefarm/systemSetting/INIT_USER_PGM_AUTH_LIST"
export const SET_USER_PGM_AUTH_LIST = "@elefarm/systemSetting/SET_USER_PGM_AUTH_LIST"

// 사용자언어 다국어 - 유용준
  // 대분류 - 차건담 2023.01.30
  export const INIT_UMAJOR_SMAJOR_SMINOR = "@elefarm/systemSetting/INIT_UMAJOR_SMAJOR_SMINOR"
  export const SET_UMAJOR_SMAJOR_SMINOR = "@elefarm/systemSetting/SET_UMAJOR_SMAJOR_SMINOR"
  
  // 소분류
  export const INIT_USER_UDLanguage_CODE = "@elefarm/systemSetting/INIT_USER_UDLanguage_CODE"
  export const SET_USER_UDLanguage_CODE = "@elefarm/systemSetting/SET_USER_UDLanguage_CODE"


// 시스템언어 다국어 - 차건담 2023.01.20
  export const INIT_SYS_MAJOR_CODE = "@elefarm/systemSetting/INIT_SYS_MAJOR_CODE"
  export const SET_SYS_MAJOR_CODE  = "@elefarm/systemSetting/SET_SYS_MAJOR_CODE"        

  export const INIT_SYS_MINOR_CODE = "@elefarm/systemSetting/INIT_SYS_MINOR_CODE"
  export const SET_SYS_MINOR_CODE  = "@elefarm/systemSetting/SET_SYS_MINOR_CODE"      

  export const INIT_SYS_SDLANGUAGE_CODE = "@elefarm/systemSetting/INIT_SYS_SDLANGUAGE_CODE"
  export const SET_SYS_SDLANGUAGE_CODE = "@elefarm/systemSetting/SET_SYS_SDLANGUAGE_CODE"

// 언어종류 - 차건담 2023.01.30
  export const INIT_LANGUAGEKIND = "@elefarm/systemSetting/INIT_LANGUAGEKIND"
  export const SET_LANGUAGEKIND = "@elefarm/systemSetting/SET_LANGUAGEKIND"

/* action function */
export const setModuleList           = createAction(SET_MODULE_LIST)
export const setMenuList             = createAction(SET_MENU_LIST)
export const setCodeList             = createAction(SET_CODE_LIST)
export const setCodeHelpItem         = createAction(SET_CODE_HELP_ITEM)
export const setCodeHelpCustomer     = createAction(SET_CODE_HELP_CUSTOMER)
export const setComboWorkCenterList  = createAction(SET_COMBO_WORK_CENTER_LIST)
export const setComboGatewayList     = createAction(SET_COMBO_GATEWAY_LIST)
export const setComboControlNodeList = createAction(SET_COMBO_CONTROL_NODE_LIST)
export const setComboEquipList       = createAction(SET_COMBO_EQUIP_LIST)
export const setComboStdCropList     = createAction(SET_COMBO_STDCROP_LIST)
export const setComboCropList        = createAction(SET_COMBO_CROP_LIST)
export const setComboCropGrowthList  = createAction(SET_COMBO_CROP_GROWTH_LIST)
export const setBookmarkList         = createAction(SET_BOOK_MARK_LIST)

export const initUserMajorCode       = createAction(INIT_USER_MAJOR_CODE)
export const setUserMajorCode        = createAction(SET_USER_MAJOR_CODE)
export const initUserMinorCode       = createAction(INIT_USER_MINOR_CODE)
export const setUserMinorCode        = createAction(SET_USER_MINOR_CODE)
export const setEnvSettingInfo       = createAction(SET_ENV_SETTING_INFO)
export const setModuleAuthList       = createAction(SET_MODULE_AUTH_LIST)
export const initUserPgmAuthMenuList = createAction(INIT_USER_PGM_AUTH_MENU_LIST)
export const setUserPgmAuthMenuList  = createAction(SET_USER_PGM_AUTH_MENU_LIST)
export const initUserPgmAuthList     = createAction(INIT_USER_PGM_AUTH_LIST)
export const setUserPgmAuthList      = createAction(SET_USER_PGM_AUTH_LIST)

// 사용자언어 다국어
  // 소분류 - 유용준
  export const initUserUDLanguageCode  = createAction(INIT_USER_UDLanguage_CODE)
  export const setUserUDLanguageCode   = createAction(SET_USER_UDLanguage_CODE)

// 시스템언어 다국어 - 차건담 20023.01.20
  export const initSysMajorCode      = createAction(INIT_SYS_MAJOR_CODE)      
  export const setSysMajorCode       = createAction(SET_SYS_MAJOR_CODE)                 
  export const initSysMinorCode      = createAction(INIT_SYS_MINOR_CODE)     
  export const setSysMinorCode       = createAction(SET_SYS_MINOR_CODE)               
  export const initSDLanguageCode    = createAction(INIT_SYS_SDLANGUAGE_CODE)
  export const setSDLanguageCode     = createAction(SET_SYS_SDLANGUAGE_CODE)

// 다국어 공통
  export const initUMajorSMajorSMinor  = createAction(INIT_UMAJOR_SMAJOR_SMINOR)
  export const setUMajorSMajorSMinor  = createAction(SET_UMAJOR_SMAJOR_SMINOR) 

// 언어종류 - 차건담 2023.01.30
  export const initLanguageKind      = createAction(INIT_LANGUAGEKIND)
  export const setLanguageKind      = createAction(SET_LANGUAGEKIND)

/* action api function */
// 모듈 리스트
export const getModuleList = (param) => async (dispatch) => {
  const result = await SystemSettingApis.getModuleList(param)
  if (result.code === 0) {
    dispatch(setModuleList(result.data.systemModuleList))
    return true
  } else return false
}
// 메뉴 리스트
export const getMenuList = (param) => async (dispatch) => {
  const result = await SystemSettingApis.getMenuList(param)
  if (result.code === 0) {
    dispatch(setMenuList(result.data.systemMenuList))
    return result.data.systemMenuList
  } else return false
}
// 코드분류 리스트
export const getCodeList = (param) => async (dispatch) => {
  const result = await SystemSettingApis.getCodeList(param)
  if (result.code === 0) {
    dispatch(setCodeList(result.data.pgmMinorList))
    return true
  } else return false
}
// 코드도움 - 품목
export const getCodeHelpItem = (param) => async (dispatch) => {
  const result = await SystemSettingApis.getCodeHelpItem(param)
  if (result.code === 0) {
    dispatch(setCodeHelpItem(result.data.dataList))
    return true
  } else return false
}
// 코드도움 - 거래처
export const getCodeHelpCustomer = (param) => async (dispatch) => {
  const result = await SystemSettingApis.getCodeHelpCustomer(param)
  if (result.code === 0) {
    dispatch(setCodeHelpCustomer(result.data.dataList))
    return true
  } else return false
}
// 콤보 (WorkCenter, Gateway, ControlNode, Equip)
export const getComboList = (param) => async (dispatch) => {
  const result = await SystemSettingApis.getComboList(param)
  if (result.code === 0) {
    switch (param.queryKind) {
      case "WorkCenter":
        dispatch(setComboWorkCenterList(result.data.dataList))
        break
      case "Gateway":
        dispatch(setComboGatewayList(result.data.dataList))
        break
      case "ControlNode":
        dispatch(setComboControlNodeList(result.data.dataList))
        break
      case "Equip":
        dispatch(setComboEquipList(result.data.dataList))
        break
      case "StdCrop":
        dispatch(setComboStdCropList(result.data.dataList))
        break
      case "Crop":
        dispatch(setComboCropList(result.data.dataList))
        break
      case "CropGrowth":
        dispatch(setComboCropGrowthList(result.data.dataList))
        break
    }

    return true
  } else return false
}
// 즐겨찾기 리스트
export const getBookmarkList = (param) => async (dispatch) => {
  const result = await CommonApis.getApi("/system/bookmark/list", param)
  if (result.code === 0) {
    dispatch(setBookmarkList(result.data.bookmarkList))
    return true
  } else return false
}
// 즐겨찾기 수정(추가/삭제)
export const editBookmark = (param) => async (dispatch) => {
  const result = await CommonApis.postApi("/system/bookmark/mod", param)
  if (result.code === 0) {
    return true
  } else return false
}

// 환경설정 정보
export const getEnvSettingInfo = (param) => async (dispatch) => {
  const result = await SystemSettingApis.getEnvSettingInfo(param)
  if (result.code === 0) {
    dispatch(setEnvSettingInfo(result.data.settingInfo))
    return true
  } else return false
}
// 환경설정 정보 저장
export const editEnvSettingInfo = (param) => async (dispatch) => {
  const result = await SystemSettingApis.editEnvSettingInfo(param)
  if (result.code === 0) {
    
    localStorage.setItem(TOKEN_ID, result.data.accessToken)

    return true
  } else return false
}

// 언어종류
  export const getLanguageKind = () => async (dispatch) => {
    const result = await SystemSettingApis.getLanguageKind()
    if (result.code === 0) {
      dispatch(setLanguageKind(result.data.languageKind))
      return true
    } else return false
  }

// 다국어 관련 =======================================================
  //공통
    // 사용자코드(사용자설정언어 대분류, 시스템언어 대분류/소분류 조회, 차건담) - 2023.01.30
    export const getUMajorSMajorSMinor = (param) => async (dispatch) => {
      const result = await SystemSettingApis.getUMajorSMajorSMinor(param)
      if (result.code === 0) {
        dispatch(setUMajorSMajorSMinor(result.data.UMajorSMajorSMinorList))
        return true
      } else return false
    }  
  
  // 사용자코드 관련

    // 사용자코드(대분류조회)
    export const getUserMajorCode = (param) => async (dispatch) => {
      const result = await SystemSettingApis.getUserMajorCode(param)
      if (result.code === 0) {
        dispatch(setUserMajorCode(result.data.systemTUMajorList))
        return true
      } else return false
    }
    // 사용자코드(소분류조회)
    export const getUserMinorCode = (param) => async (dispatch) => {
      const result = await SystemSettingApis.getUserMinorCode(param)
      if (result.code === 0) {
        dispatch(setUserMinorCode(result.data.systemTUMinorList))
        return true
      } else return false
    }
    // 사용자코드(소분류추가)
    export const addUserMinorCode = (param) => async (dispatch) => {
      const result = await SystemSettingApis.addUserMinorCode(param)
      if (result.code === 0) {
        return true
      } else return false
    }
    // 사용자코드(소분류수정)
    export const editUserMinorCode = (param) => async (dispatch) => {
      const result = await SystemSettingApis.editUserMinorCode(param)
      if (result.code === 0) {
        return true
      } else return false
    }
    // 사용자코드(소분류삭제)
    export const delUserMinorCode = (param) => async (dispatch) => {
      const result = await SystemSettingApis.delUserMinorCode(param)
      if (result.code === 0) {
        return true
      } else return false
    }
    // 사용자코드(소분류 다국어 조회, 유용준)
    export const getUserDefLanguageCode = (param) => async (dispatch) => {
      const result = await SystemSettingApis.getUDLanguageCode(param)
      if (result.code === 0) {
        dispatch(setUserUDLanguageCode(result.data.systemTUDLanguageList))
        return true
      } else return false
    }
    // 사용자코드(소분류 다국어 수정, 유용준)
    export const editUserDefLanguageCode = (param) => async (dispatch) => {
      const result = await SystemSettingApis.editUDLanguageCode(param)
      if (result.code === 0) {
        return true
      } else return false
    }
    // 사용자코드(소분류 다국어 추가, 차건담) - 2023.02.07
    export const insertUserDefLanguageCode = (param) => async (dispatch) => {
      const result = await SystemSettingApis.insertUDLanguageCode(param)
      if (result.code === 0) {
        return true
      } else return false
    }

    // 사용자코드(소분류 다국어 삭제, 차건담) - 2023.02.13
    export const delUserDefLanguageCode = (param) => async (dispatch) => {
      const result = await SystemSettingApis.delUDLanguageCode(param)
      if (result.code === 0) {
        return true
      } else return false
    }    

  // 시스템코드 관련

    // 시스템코드(대분류 다국어 조회)
    export const getSysMajorCode = (param) => async (dispatch) => {
      const result = await SystemSettingApis.getSysMajorCode(param)
      if (result.code === 0) {
        dispatch(setSysMajorCode(result.data.systemTSMajorList))
        return true
      } else return false
    }
    // 시스템코드(소분류 다국어 조회)
    export const getSysMinorCode = (param) => async (dispatch) => {
      const result = await SystemSettingApis.getSysMinorCode(param)
      if (result.code === 0) {
        dispatch(setSysMinorCode(result.data.systemTSMinorList))
        return true
      } else return false
    }
    // 사용자 대분류, 시스템 대분류/소분류 다국어 조회- 차건담
    export const getSDLanguageCode = (param) => async (dispatch) => {
      const result = await SystemSettingApis.getSDLanguageCode(param)
      if (result.code === 0) {
        dispatch(setSDLanguageCode(result.data.systemTSDLanguageList))
        return true
      } else return false
    }
    // 사용자 대분류, 시스템 대분류/소분류 다국어 수정- 차건담 - 2023.02.06
    export const editUMajorSMajorSMinor = (param) => async (dispatch) => {
      const result = await SystemSettingApis.editUMajorSMajorSMinor(param)
      if (result.code === 0) {
        return true
      } else return false
    }

    // 사용자 대분류, 시스템 대분류/소분류 다국어 추가- 차건담 - 2023.02.07
    export const insertUMajorSMajorSMinor = (param) => async (dispatch) => {
      const result = await SystemSettingApis.insertUMajorSMajorSMinor(param)
      if (result.code === 0) {
        return true
      } else return false
    }
//=======================================================================


// 모듈권한 리스트
export const getModuleAuthList = (param) => async (dispatch) => {
  const result = await SystemSettingApis.getModuleAuthList(param)
  if (result.code === 0) {
    dispatch(setModuleAuthList(result.data.userModuleAuthList))
    return true
  } else return false
}
// 모듈권한 수정
export const editModuleAuth = (param) => async (dispatch) => {
  const result = await SystemSettingApis.editModuleAuth(param)
  if (result.code === 0) {
    return true
  } else return false
}
// 프로그램권한 리스트(모듈,메뉴)
export const getUserPgmAuthMenuList = (param) => async (dispatch) => {
  const result = await SystemSettingApis.getUserPgmAuthMenuList(param)
  if (result.code === 0) {
    dispatch(setUserPgmAuthMenuList(result.data.userPgmAuthMenuList))
    return true
  } else return false
}
// 프로그램권한 리스트(프로그램)
export const getUserPgmAuthList = (param) => async (dispatch) => {
  const result = await SystemSettingApis.getUserPgmAuthList(param)
  if (result.code === 0) {
    dispatch(setUserPgmAuthList(result.data.userPgmAuthList))
    return true
  } else return false
}
// 프로그램권한 수정
export const editUserPgmAuth = (param) => async (dispatch) => {
  const result = await SystemSettingApis.editUserPgmAuth(param)
  if (result.code === 0) {
    return true
  } else return false
}

export const MAIN_DATA_LIST = { uri : '/farm/main/info', id : 'mainDataList', initData : {
  sensorData1Info : {},
  sensorData4Info : {},
  sensorDataList : [],
  equipDataList : []
} }

// 추가 2024.03.18 by 강동우
export const INIT_TRANSLATION_WORDS = "@elefarm/common/INIT_TRANSLATION_WORDS"
export const initTranslationWords = createAction(INIT_TRANSLATION_WORDS)

export const SET_TRANSLATION_WORDS = "@elefarm/common/SET_TRANSLATION_WORDS"

export const setTranslationWords = createAction(SET_TRANSLATION_WORDS)

export const getTranslationWords = () => async (dispatch) => {
  const result = await CommonApis.getTranslationWords()
  console.log("commonAction result: ", result)
  if (result.code === 0) {    
    dispatch(setTranslationWords(result.data.TranslationWords))
    return true
  } else return false  
}
