import { handleActions } from "redux-actions"
import actions from "../actions"

// action type load
const {
  SET_FARM_INFO,

  SET_USER_LIST,
  INIT_USER,
  SET_USER,

  INIT_ITEM_LIST,
  SET_ITEM_LIST,
  INIT_ITEM,
  SET_ITEM,

  INIT_CUSTOMER_LIST,
  SET_CUSTOMER_LIST,
  INIT_CUSTOMER,
  SET_CUSTOMER,

  INIT_WORK_CENTER_LIST,
  SET_WORK_CENTER_LIST,
  INIT_WORK_CENTER,
  SET_WORK_CENTER,

  INIT_GATE_WAY_LIST,
  SET_GATE_WAY_LIST,
  INIT_GATE_WAY,
  SET_GATE_WAY,

  INIT_SENSOR_NODE_LIST,
  SET_SENSOR_NODE_LIST,
  INIT_SENSOR_NODE,
  SET_SENSOR_NODE,
  INIT_SENSOR_LIST,
  SET_SENSOR_LIST,
  INIT_SENSOR,
  SET_SENSOR,

  INIT_CONTROL_NODE_LIST,
  SET_CONTROL_NODE_LIST,
  INIT_CONTROL_NODE,
  SET_CONTROL_NODE,

  INIT_PRODUCTION_LINE_LIST,
  SET_PRODUCTION_LINE_LIST,
  INIT_PRODUCTION_LINE,
  SET_PRODUCTION_LINE,

  INIT_PRODUCTION_LINE_LIST_DIV,
  SET_PRODUCTION_LINE_LIST_DIV,
} = actions.DefaultInfoAction

// default state value
const initialState = {
  _farmInfo: {},
  _userList: [],
  _user: {},
  _itemList: [],
  _item: {},
  _customerList: [],
  _customer: {},
  _workCenterList: [],
  _workCenter: {},
  _gateWayList: [],
  _gateWay: {},
  _sensorNodeList: [],
  _sensorNode: {},
  _sensorList: [],
  _sensor: {},
  _controlNodeList: [],
  _controlNode: {},
  _productionLineList: [],
  _productionLine: {},
  _productionLineListDiv: [],
}

// handle action
export default handleActions(
  {
    [SET_FARM_INFO]: (state, action) => ({
      ...state,
      _farmInfo: action.payload,
    }),

    [SET_USER_LIST]: (state, action) => ({
      ...state,
      _userList: action.payload,
    }),
    [INIT_USER]: (state, action) => ({
      ...state,
      _user: {},
    }),
    [SET_USER]: (state, action) => ({
      ...state,
      _user: action.payload,
    }),

    [INIT_ITEM_LIST]: (state, action) => ({
      ...state,
      _itemList: [],
    }),
    [SET_ITEM_LIST]: (state, action) => ({
      ...state,
      _itemList: action.payload,
    }),
    [INIT_ITEM]: (state, action) => ({
      ...state,
      _item: {},
    }),
    [SET_ITEM]: (state, action) => ({
      ...state,
      _item: action.payload,
    }),

    [INIT_CUSTOMER_LIST]: (state, action) => ({
      ...state,
      _customerList: [],
    }),
    [SET_CUSTOMER_LIST]: (state, action) => ({
      ...state,
      _customerList: action.payload,
    }),
    [INIT_CUSTOMER]: (state, action) => ({
      ...state,
      _customer: {},
    }),
    [SET_CUSTOMER]: (state, action) => ({
      ...state,
      _customer: action.payload,
    }),

    [INIT_WORK_CENTER_LIST]: (state, action) => ({
      ...state,
      _workCenterList: [],
    }),
    [SET_WORK_CENTER_LIST]: (state, action) => ({
      ...state,
      _workCenterList: action.payload,
    }),
    [INIT_WORK_CENTER]: (state, action) => ({
      ...state,
      _workCenter: {},
    }),
    [SET_WORK_CENTER]: (state, action) => ({
      ...state,
      _workCenter: action.payload,
    }),

    [INIT_GATE_WAY_LIST]: (state, action) => ({
      ...state,
      _gateWayList: [],
    }),
    [SET_GATE_WAY_LIST]: (state, action) => ({
      ...state,
      _gateWayList: action.payload,
    }),
    [INIT_GATE_WAY]: (state, action) => ({
      ...state,
      _gateWay: {},
    }),
    [SET_GATE_WAY]: (state, action) => ({
      ...state,
      _gateWay: action.payload,
    }),

    [INIT_SENSOR_NODE_LIST]: (state, action) => ({
      ...state,
      _sensorNodeList: [],
    }),
    [SET_SENSOR_NODE_LIST]: (state, action) => ({
      ...state,
      _sensorNodeList: action.payload,
    }),
    [INIT_SENSOR_NODE]: (state, action) => ({
      ...state,
      _sensorNode: {},
    }),
    [SET_SENSOR_NODE]: (state, action) => ({
      ...state,
      _sensorNode: action.payload,
    }),
    [INIT_SENSOR_LIST]: (state, action) => ({
      ...state,
      _sensorList: [],
    }),
    [SET_SENSOR_LIST]: (state, action) => ({
      ...state,
      _sensorList: action.payload,
    }),
    [INIT_SENSOR]: (state, action) => ({
      ...state,
      _sensor: {},
    }),
    [SET_SENSOR]: (state, action) => ({
      ...state,
      _sensor: action.payload,
    }),

    [INIT_CONTROL_NODE_LIST]: (state, action) => ({
      ...state,
      _controlNodeList: [],
    }),
    [SET_CONTROL_NODE_LIST]: (state, action) => ({
      ...state,
      _controlNodeList: action.payload,
    }),
    [INIT_CONTROL_NODE]: (state, action) => ({
      ...state,
      _controlNode: {},
    }),
    [SET_CONTROL_NODE]: (state, action) => ({
      ...state,
      _controlNode: action.payload,
    }),

    [INIT_PRODUCTION_LINE_LIST]: (state, action) => ({
      ...state,
      _productionLineList: [],
    }),
    [SET_PRODUCTION_LINE_LIST]: (state, action) => ({
      ...state,
      _productionLineList: action.payload,
    }),
    [INIT_PRODUCTION_LINE]: (state, action) => ({
      ...state,
      _productionLine: {},
    }),
    [SET_PRODUCTION_LINE]: (state, action) => ({
      ...state,
      _productionLine: action.payload,
    }),

    [INIT_PRODUCTION_LINE_LIST_DIV]: (state, action) => ({
      ...state,
      _productionLineListDiv: [],
    }),
    [SET_PRODUCTION_LINE_LIST_DIV]: (state, action) => ({
      ...state,
      _productionLineListDiv: action.payload,
    }),
  },
  initialState
)
