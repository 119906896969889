// 파일 생성 - 차건담 | 마지막 수정 날짜 - 2023.06.13
import { handleActions } from "redux-actions"
import actions from "../actions"

// action type load
const {
    SET_WORKCENTER_COMBO_LIST,
    SET_SENSORNODE_COMBO_LIST,
    SET_SENSORKIND_COMBO_LIST,

    INIT_CHART_DATA_LIST,
    SET_CHART_DATA_LIST,

    INIT_COMPARE_CHART_DATA_LIST,
    SET_COMPARE_CHART_DATA_LIST,

    INIT_SENSOR_MINMAX,
    SET_SENSOR_MINMAX,

    INIT_TARGETVALUE_LIST,
    SET_TARGETVALUE_LIST,
} = actions.ChartAction

// default state value
const initialState = {
    _workCenterComboList: [],
    _sensorNodeComboList: [],
    _sensorKindComboList: [],
    _chartDataList: [],
    _compareChartDataList: [],
    _sensorMinMax: [],
    _targetValueList: [],
}

// handle action
export default handleActions(
    {
        [SET_WORKCENTER_COMBO_LIST]: (state, action) => ({
            ...state,
            _workCenterComboList: action.payload,
        }),
        [SET_SENSORNODE_COMBO_LIST]: (state, action) => ({
            ...state,
            _sensorNodeComboList: action.payload,
        }),
        [SET_SENSORKIND_COMBO_LIST]: (state, action) => ({
            ...state,
            _sensorKindComboList: action.payload,
        }),

        [INIT_CHART_DATA_LIST]: (state, action) => ({
            ...state,
            _chartDataList: [],
        }),
        [SET_CHART_DATA_LIST]: (state, action) => ({
            ...state,
            _chartDataList: action.payload,
        }),

        [INIT_COMPARE_CHART_DATA_LIST]: (state, action) => ({
            ...state,
            _compareChartDataList: [],
        }),
        [SET_COMPARE_CHART_DATA_LIST]: (state, action) => ({
            ...state,
            _compareChartDataList: action.payload,
        }),        

        [INIT_SENSOR_MINMAX]: (state, action) => ({
            ...state,
            _sensorMinMax: [],
        }),
        [SET_SENSOR_MINMAX]: (state, action) => ({
            ...state,
            _sensorMinMax: action.payload,
        }),        

        [INIT_TARGETVALUE_LIST]: (state, action) => ({
            ...state,
            _targetValueList: [],
        }),
        [SET_TARGETVALUE_LIST]: (state, action) => ({
            ...state,
            _targetValueList: action.payload,
        }),   
    },
    initialState
)