import { createAction } from "redux-actions"
import CommonApis from "../apis/CommonApis"

/* action type */
export const INIT_AUTO_ENV_WORK_CENTER_LIST = "@elefarm/envControl/INIT_AUTO_ENV_WORK_CENTER_LIST"
export const SET_AUTO_ENV_WORK_CENTER_LIST = "@elefarm/envControl/SET_AUTO_ENV_WORK_CENTER_LIST"
export const INIT_AUTO_ENV_EQUIP_LIST = "@elefarm/envControl/INIT_AUTO_ENV_EQUIP_LIST"
export const SET_AUTO_ENV_EQUIP_LIST = "@elefarm/envControl/SET_AUTO_ENV_EQUIP_LIST"
export const INIT_AUTO_ENV_EQUIP = "@elefarm/envControl/INIT_AUTO_ENV_EQUIP"
export const SET_AUTO_ENV_EQUIP = "@elefarm/envControl/SET_AUTO_ENV_EQUIP"
export const SET_SENSOR_DATA = "@elefarm/envControl/SET_SENSOR_DATA"

export const INIT_AUTO_ENV_EQUIP_TIME = "@elefarm/envControl/INIT_AUTO_ENV_EQUIP_TIME"
export const SET_AUTO_ENV_EQUIP_TIME = "@elefarm/envControl/SET_AUTO_ENV_EQUIP_TIME"

export const INIT_AUTO_ENV_CONF_EQUIP_ON_OFF = "@elefarm/envControl/INIT_AUTO_ENV_CONF_EQUIP_ON_OFF"
export const SET_AUTO_ENV_CONF_EQUIP_ON_OFF = "@elefarm/envControl/SET_AUTO_ENV_CONF_EQUIP_ON_OFF"
export const INIT_AUTO_ENV_CONF_EQUIP_ON_OFF_EX = "@elefarm/envControl/INIT_AUTO_ENV_CONF_EQUIP_ON_OFF_EX"
export const SET_AUTO_ENV_CONF_EQUIP_ON_OFF_EX = "@elefarm/envControl/SET_AUTO_ENV_CONF_EQUIP_ON_OFF_EX"

export const INIT_INTERLOCK_LIST = "@elefarm/envControl/INIT_INTERLOCK_LIST"
export const SET_INTERLOCK_LIST = "@elefarm/envControl/SET_INTERLOCK_LIST"
export const INIT_INTERLOCK = "@elefarm/envControl/INIT_INTERLOCK"
export const SET_INTERLOCK = "@elefarm/envControl/SET_INTERLOCK"

export const INIT_EQUIP_CONTROL_FORREV_LIST = "@elefarm/envControl/INIT_EQUIP_CONTROL_FORREV_LIST"
export const SET_EQUIP_CONTROL_FORREV_LIST = "@elefarm/envControl/SET_EQUIP_CONTROL_FORREV_LIST"
export const INIT_EQUIP_CONTROL_ONOFF_LIST = "@elefarm/envControl/INIT_EQUIP_CONTROL_ONOFF_LIST"
export const SET_EQUIP_CONTROL_ONOFF_LIST = "@elefarm/envControl/SET_EQUIP_CONTROL_ONOFF_LIST"

export const INIT_EQUIP_CONTROL_STATUS = "@elefarm/envControl/INIT_EQUIP_CONTROL_STATUS"
export const SET_EQUIP_CONTROL_STATUS = "@elefarm/envControl/SET_EQUIP_CONTROL_STATUS"

//긴급경보알림
export const INIT_EMERGENCYALARM_LIST = "@elefarm/envControl/INIT_EMERGENCYALARM_LIST"
export const SET_EMERGENCYALARM_LIST = "@elefarm/envControl/SET_EMERGENCYALARM_LIST"
export const INIT_EMERGENCYALARM = "@elefarm/envControl/INIT_EMERGENCYALARM"
export const SET_EMERGENCYALARM = "@elefarm/envControl/SET_EMERGENCYALARM"

// 복합제어 - 목표값 조건변경
export const SET_CONF_RESETTING_INFO = "@elefarm/envControl/SET_CONF_RESETTING_INFO"

/* action function */
export const initAutoEnvWorkCenterList = createAction(INIT_AUTO_ENV_WORK_CENTER_LIST)
export const setAutoEnvWorkCenterList = createAction(SET_AUTO_ENV_WORK_CENTER_LIST)
export const initAutoEnvEquipList = createAction(INIT_AUTO_ENV_EQUIP_LIST)
export const setAutoEnvEquipList = createAction(SET_AUTO_ENV_EQUIP_LIST)
export const initAutoEnvEquip = createAction(INIT_AUTO_ENV_EQUIP)
export const setAutoEnvEquip = createAction(SET_AUTO_ENV_EQUIP)
export const setSensorData = createAction(SET_SENSOR_DATA)

export const initAutoEnvEquipTime = createAction(INIT_AUTO_ENV_EQUIP_TIME)
export const setAutoEnvEquipTime = createAction(SET_AUTO_ENV_EQUIP_TIME)

export const initAutoEnvConfEquipOnOff = createAction(INIT_AUTO_ENV_CONF_EQUIP_ON_OFF)
export const setAutoEnvConfEquipOnOff = createAction(SET_AUTO_ENV_CONF_EQUIP_ON_OFF)
export const initAutoEnvConfEquipOnOffEx = createAction(INIT_AUTO_ENV_CONF_EQUIP_ON_OFF_EX)
export const setAutoEnvConfEquipOnOffEx = createAction(SET_AUTO_ENV_CONF_EQUIP_ON_OFF_EX)

export const initInterLockList = createAction(INIT_INTERLOCK_LIST)
export const setInterLockList = createAction(SET_INTERLOCK_LIST)
export const initInterLock = createAction(INIT_INTERLOCK)
export const setInterLock = createAction(SET_INTERLOCK)

export const initEquipControlForRevList = createAction(INIT_EQUIP_CONTROL_FORREV_LIST)
export const setEquipControlForRevList = createAction(SET_EQUIP_CONTROL_FORREV_LIST)
export const initEquipControlOnOffList = createAction(INIT_EQUIP_CONTROL_ONOFF_LIST)
export const setEquipControlOnOffList = createAction(SET_EQUIP_CONTROL_ONOFF_LIST)

export const initEquipControlStatus = createAction(INIT_EQUIP_CONTROL_STATUS)
export const setEquipControlStatus = createAction(SET_EQUIP_CONTROL_STATUS)

//긴급경보알림
export const initEmergencyAlarmList = createAction(INIT_EMERGENCYALARM_LIST)
export const setEmergencyAlarmList = createAction(SET_EMERGENCYALARM_LIST)
export const initEmergencyAlarm = createAction(INIT_EMERGENCYALARM)
export const setEmergencyAlarm = createAction(SET_EMERGENCYALARM)

// 복합제어 - 목표값 조건변경
export const setConfReSetting = createAction(SET_CONF_RESETTING_INFO)

/* action api function */
// 자동환경설정 작업장리스트
export const getAutoEnvWorkCenterList = (param) => async (dispatch) => {
    const result = await CommonApis.getApi("/product/workCenter/list", param)

    if (result.code === 0) {
        dispatch(setAutoEnvWorkCenterList(result.data.envWorkCenterList))

        return true
    } else return false
}
// 자동환경설정 생산설비리스트
export const getAutoEnvEquipList = (param) => async (dispatch) => {
    const result = await CommonApis.getApi(`/product/equip/list`, param)

    if (result.code === 0) {
        if (param.type == 1) {
            dispatch(setAutoEnvEquipList(result.data.envEquipList.filter((i) => i.workKind == 10180002)))
        } else if (param.type == 2) {
            dispatch(setAutoEnvEquipList(result.data.envEquipList.filter((i) => i.workKind == 10180001)))
        } else {
            dispatch(setAutoEnvEquipList(result.data.envEquipList))
        } // end if

        return true
    } else {
        return false
    } // end if
}
// 자동환경설정 생산설비별정보
export const getAutoEnvEquip = (param) => async (dispatch) => {
    const result = await CommonApis.getApi("/product/envConf/info", param)
    if (result.code === 0) {
        dispatch(setAutoEnvEquip(result.data))
        return true
    } else return false
}
// 센서데이터(일출, 일몰시간)
export const getSensorData = (param) => async (dispatch) => {
    const result = await CommonApis.getApi("/sensor/data/light", param)

    if (result.code === 0) {
        dispatch(setSensorData(result.data.dataList))
        return true
    } else return false
}

// 자동환경설정 생산설비별 목표온도추가
export const addAutoEnvEquipTime = (param) => async (dispatch) => {
    const result = await CommonApis.postApi("/product/envConfEquipTime/add", param)
    if (result.code === 0) {
        return true
    } else return false
}
// 자동환경설정 생산설비별 목표온도정보
export const getAutoEnvEquipTime = (param) => async (dispatch) => {
    const result = await CommonApis.getApi("/product/envConfEquipTime/info", param)
    if (result.code === 0) {
        dispatch(setAutoEnvEquipTime(result.data.envConfEquipTimeInfo))
        return true
    } else return false
}
// 자동환경설정 생산설비별 목표온도수정
export const editAutoEnvEquipTime = (param) => async (dispatch) => {
    const result = await CommonApis.postApi("/product/envConfEquipTime/mod", param)
    if (result.code === 0) {
        return true
    } else return false
}
// 자동환경설정 생산설비별 목표온도삭제
export const delAutoEnvEquipTime = (param) => async (dispatch) => {
    const result = await CommonApis.postApi("/product/envConfEquipTime/del", param)
    if (result.code === 0) {
        return true
    } else return false
}
// 자동환경설정 생산설비별 설정항목저장
export const editAutoEnvConfEquip = (param) => async (dispatch) => {
    const result = await CommonApis.postApi("/product/envConfEquip/add", param)
    if (result.code === 0) {
        return true
    } else return false
}
// 자동환경설정 생산설비별 일사량정보저장
export const editAutoEnvConfEquipTemp = (param) => async (dispatch) => {
    const result = await CommonApis.postApi("/product/envConfEquipTemp/add", param)
    if (result.code === 0) {
        return true
    } else return false
}

// 자동환경설정 생산설비별 On/Off 추가
export const addAutoEnvConfEquipOnOff = (param) => async (dispatch) => {
    const result = await CommonApis.postApi("/product/envConfEquipOnOff/add", param)
    if (result.code === 0) {
        return true
    } else return false
}
// 자동환경설정 생산설비별 On/Off 정보
export const getAutoEnvConfEquipOnOff = (param) => async (dispatch) => {
    const result = await CommonApis.getApi("/product/envConfEquipOnOff/info", param)
    if (result.code === 0) {
        dispatch(setAutoEnvConfEquipOnOff(result.data.envConfEquipOnOffInfo[0]))
        return true
    } else return false
}
// 자동환경설정 생산설비별 On/Off 정보 수정
export const editAutoEnvConfEquipOnOff = (param) => async (dispatch) => {
    const result = await CommonApis.postApi("/product/envConfEquipOnOff/mod", param)
    if (result.code === 0) {
        return true
    } else return false
}
// 자동환경설정 생산설비별 On/Off 삭제
export const delAutoEnvConfEquipOnOff = (param) => async (dispatch) => {
    const result = await CommonApis.postApi("/product/envConfEquipOnOff/del", param)
    if (result.code === 0) {
        return true
    } else return false
}
// 자동환경설정 생산설비별 On/Off 제어예외조건 추가
export const addAutoEnvConfEquipOnOffEx = (param) => async (dispatch) => {
    const result = await CommonApis.postApi("/product/envConfEquipOnOffEx/add", param)
    if (result.code === 0) {
        return true
    } else return false
}
// 자동환경설정 생산설비별 On/Off 제어예외조건 정보
export const getAutoEnvConfEquipOnOffEx = (param) => async (dispatch) => {
    const result = await CommonApis.getApi("/product/envConfEquipOnOffEx/info", param)
    if (result.code === 0) {
        dispatch(setAutoEnvConfEquipOnOffEx(result.data.envConfEquipOnOffExInfo))
        return true
    } else return false
}
// 자동환경설정 생산설비별 On/Off 제어예외조건 정보 수정
export const editAutoEnvConfEquipOnOffEx = (param) => async (dispatch) => {
    const result = await CommonApis.postApi("/product/envConfEquipOnOffEx/mod", param)
    if (result.code === 0) {
        return true
    } else return false
}
// 자동환경설정 생산설비별 On/Off 제어예외조건 삭제
export const delAutoEnvConfEquipOnOffEx = (param) => async (dispatch) => {
    const result = await CommonApis.postApi("/product/envConfEquipOnOffEx/del", param)
    if (result.code === 0) {
        return true
    } else return false
}

// 인터락설정 리스트
export const getInterLockList = (param) => async (dispatch) => {
    const result = await CommonApis.getApi("/product/equipInterLock/list", param)
    if (result.code === 0) {
        dispatch(setInterLockList(result.data.equipInterLockList))
        return true
    } else return false
}
// 인터락설정 수정
export const editInterLockIsUse = (param) => async (dispatch) => {
    const result = await CommonApis.postApi("/product/equipInterLock/isUse/mod", param)
    if (result.code === 0) {
        return true
    } else return false
}
// 인터락설정 정보
export const getInterLock = (param) => async (dispatch) => {
    const result = await CommonApis.getApi("/product/equipInterLock/info", param)
    if (result.code === 0) {
        dispatch(setInterLock(result.data.equipInterLockInfo))
        return true
    } else return false
}
// 인터락설정 정보 수정
export const editInterLock = (param) => async (dispatch) => {
    const result = await CommonApis.postApi("/product/equipInterLock/mod", param)
    if (result.code === 0) {
        return true
    } else return false
}

// 긴급경보알림 리스트
export const getEmergencyAlarmList = (param) => async (dispatch) => {
    const result = await CommonApis.getApi("/product/emergencyAlarm/list", param)
    if (result.code === 0) {
        dispatch(setEmergencyAlarmList(result.data.emergencyAlarmList))
        return true
    } else return false
}
// 긴급경보알림 추가
export const addEmergencyAlarm = (param) => async (dispatch) => {
    const result = await CommonApis.postApi("/product/emergencyAlarm/add", param)
    if (result.code === 0) {
        return true
    } else return false
}
// 긴급경보알림 정보
export const getEmergencyAlarm = (param) => async (dispatch) => {
    const result = await CommonApis.getApi("/product/emergencyAlarm/info", param)
    if (result.code === 0) {
        dispatch(setEmergencyAlarm(result.data.emergencyAlarmInfo))
        return true
    } else return false
}

// 긴급경보알림 정보수정
export const editEmergencyAlarm = (param) => async (dispatch) => {
    const result = await CommonApis.postApi("/product/emergencyAlarm/mod", param)
    if (result.code === 0) {
        return true
    } else return false
}
// 긴급경보알림 삭제
export const delEmergencyAlarm = (param) => async (dispatch) => {
    const result = await CommonApis.postApi("/product/emergencyAlarm/del", param)
    if (result.code === 0) {
        return true
    } else return false
}

// 설비제어 리스트
export const getEquipControlList = (param) => async (dispatch) => {
    const result = await CommonApis.getApi("/product/equipControl/list", param)
    if (result.code === 0) {
        dispatch(setEquipControlForRevList(result.data.dataList.equipControlForRevList))
        dispatch(setEquipControlOnOffList(result.data.dataList.equipControlOnOffList))
        return true
    } else return false
}
// 설비제어 자동/수동 수정
export const editEquipControlAuto = (param) => async (dispatch) => {
    const result = await CommonApis.postApi("/product/equipControl/auto/mod", param)
    if (result.code === 0) {
        return true
    } else return false
}
// 설비제어 열림/OnOff 수정
export const editEquipControlSetting = (param) => async (dispatch) => {
    const result = await CommonApis.postApi("/product/equipControl/setting/mod", param)
    if (result.code === 0) {
        return true
    } else return false
}
// 설비제어 제어(스위치)
export const ctrlEquipControlSwitchMod = (param) => async (dispatch) => {
    const result = await CommonApis.postApi("/product/equipControl/switch/mod", param)

    if (result.code === 0) {
        return true
    } else return false
}
// 설비제어 장비작동상태
export const getEquipControlStatus = (param) => async (dispatch) => {
    const result = await CommonApis.getApi("/product/equipControl/status", param)

    if (result.code === 0) {
        dispatch(setEquipControlStatus(result.data))
        return true
    } else return false
}

// 복합제어 목표값 조건변경 - 정보
export const getConfReSetting = (param) => async (dispatch) => {
    const result = await CommonApis.getApi("/product/envConfEquipReSetting/info", param)
    if (result.code === 0) {
        dispatch(setConfReSetting(result.data.confReSettingInfo))
        return true
    } else return false
}
// 복합제어 목표값 조건변경 - 정보추가
export const addConfReSetting = (param) => async (dispatch) => {
    const result = await CommonApis.postApi("/product/envConfEquipReSetting/add", param)
    if (result.code === 0) {
        return true
    } else return false
}
// 복합제어 목표값 조건변경 - 정보수정
export const editConfReSetting = (param) => async (dispatch) => {
    const result = await CommonApis.postApi("/product/envConfEquipReSetting/mod", param)
    if (result.code === 0) {
        return true
    } else return false
}

export const GET_CONF_LIST = { uri: "/product/envConfEquipConf/list", id: "confList", initData: [] }
export const GET_CONF_INFO = { uri: "/product/envConfEquipConf/info", id: "confInfo", initData: {} }
export const ADD_CONF_INFO = { uri: "/product/envConfEquipConf/add", id: "", initData: {} }
export const MOD_CONF_INFO = { uri: "/product/envConfEquipConf/mod", id: "", initData: {} }
export const DEL_CONF_INFO = { uri: "/product/envConfEquipConf/del", id: "", initData: {} }

//조건제어[복합]-김한중
export const GET_CONF_LISTComp = { uri: "/product/envConfEquipConfComp/list", id: "confListComp", initData: [] }
export const GET_CONF_INFOComp = { uri: "/product/envConfEquipConfComp/info", id: "confInfoComp", initData: {} }
export const GET_CONF_INFOCompDetail = { uri: "/product/envConfEquipConfComp/infoDetail", id: "confInfoCompDetail", initData: {} }
export const ADD_CONF_INFOComp = { uri: "/product/envConfEquipConfComp/add", id: "", initData: {} }
export const MOD_CONF_INFOComp = { uri: "/product/envConfEquipConfComp/mod", id: "", initData: {} }
export const MOD_CONF_INFOCompuseYN = { uri: "/product/envConfEquipConfComp/moduseYN", id: "", initData: {} }
export const DEL_CONF_INFOComp = { uri: "/product/envConfEquipConfComp/del", id: "", initData: {} }
//조건제어[복합]-김한중
