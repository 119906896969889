import api from "../utils/api"

// 로그인
const postSignIn = async (data) => {
  const result = await api.post(`/signin`, { token: false, body: data })
  return result.data
}

// 로그아웃
const postSignOut = async () => {
  const result = await api.post(`/signout`, { body: {} })
  return result.data
}

// 로그인 상태 체크
const checkSignStatus = async (data) => {
  const result = await api.post(`/signin/status`, { body: data })
  return result.data
}
// 비밀번호 찾기
const findPw = async (data) => {
  const result = await api.post(`/findPw`, { body: data })
  return result.data
}
// 회원가입
const postSignUp = async (data) => {
  const result = await api.post(`/signup`, { token: false, body: data })
  return result.data
}

export default {
  postSignIn,
  postSignOut,
  checkSignStatus,
  findPw,
  postSignUp,
}
