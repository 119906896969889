import api from "../utils/api"

// 농장 정보
const getFarmInfo = async (data) => {
  const result = await api.get(`/info/farm/info`)
  return result.data
}
// 농장 정보 수정
const editFarmInfo = async (data) => {
  const result = await api.post(`/info/farm/mod`, { body: data })
  return result.data
}

// 사용자 리스트
const getUserList = async (data) => {
  const result = await api.get(`/user/list?userName=${data.userName}&userId=${data.userId}`)
  return result.data
}
// 사용자 추가
const addUser = async (data) => {
  const result = await api.post(`/user/add`, { body: data })
  return result.data
}
// 사용자 정보
const getUser = async (data) => {
  const result = await api.get(`/user/info?userSeq=${data.userSeq}`)
  return result.data
}
// 사용자 수정
const editUser = async (data) => {
  const result = await api.post(`/user/mod`, { body: data })
  return result.data
}
// 사용자 삭제
const delUser = async (data) => {
  const result = await api.post(`/user/del`, { body: data })
  return result.data
}
// 사용자 리스트 엑셀 다운로드
const downloadUserListExcel = async (data) => {
  const result = await api.get(`/user/excel/download`, { options: { responseType: "blob" } })
  return result.data
}

// 품목 리스트
const getItemList = async (data) => {
  const result = await api.get(
    `/info/item/list?itemName=${data.itemName}&itemNo=${data.itemNo}&itemClass1=${data.itemClass1}&itemClass2=${data.itemClass2}&itemClass3=${data.itemClass3}`
  )
  return result.data
}
// 품목 추가
const addItem = async (data) => {
  const result = await api.post(`/info/item/add`, { body: data })
  return result.data
}
// 품목 정보
const getItem = async (data) => {
  const result = await api.get(`/info/item/info?itemSeq=${data.itemSeq}`)
  return result.data
}
// 품목 수정
const editItem = async (data) => {
  const result = await api.post(`/info/item/mod`, { body: data })
  return result.data
}
// 품목 삭제
const delItem = async (data) => {
  const result = await api.post(`/info/item/del`, { body: data })
  return result.data
}
// 품목 리스트 엑셀 다운로드
const downloadItemListExcel = async (data) => {
  const result = await api.get(`/info/item/excel/download`, { options: { responseType: "blob" } })
  return result.data
}

// 거래처 리스트
const getCustomerList = async (data) => {
  const result = await api.get(
    `/info/cust/list?custName=${data.custName}&custKind=${data.custKind}&custClass1=${data.custClass1}&custClass2=${data.custClass2}&custClass3=${data.custClass3}`
  )
  return result.data
}
// 거래처 추가
const addCustomer = async (data) => {
  const result = await api.post(`/info/cust/add`, { body: data })
  return result.data
}
// 거래처 정보
const getCustomer = async (data) => {
  const result = await api.get(`/info/cust/info?custSeq=${data.custSeq}`)
  return result.data
}
// 거래처 수정
const editCustomer = async (data) => {
  const result = await api.post(`/info/cust/mod`, { body: data })
  return result.data
}
// 거래처 삭제
const delCustomer = async (data) => {
  const result = await api.post(`/info/cust/del`, { body: data })
  return result.data
}
// 거래처 리스트 엑셀 다운로드
const downloadCustomerListExcel = async (data) => {
  const result = await api.get(`/info/cust/excel/download`, { options: { responseType: "blob" } })
  return result.data
}

// 작업장 리스트
const getWorkCenterList = async (data) => {
  const result = await api.get(`/info/workCenter/list?workCenterName=${data.workCenterName}`)
  return result.data
}
// 작업장 추가
const addWorkCenter = async (data) => {
  const result = await api.post(`/info/workCenter/add`, { body: data })
  return result.data
}
// 작업장 정보
const getWorkCenter = async (data) => {
  const result = await api.get(`/info/workCenter/info?workCenterSeq=${data.workCenterSeq}`)
  return result.data
}
// 작업장 수정
const editWorkCenter = async (data) => {
  const result = await api.post(`/info/workCenter/mod`, { body: data })
  return result.data
}
// 작업장 삭제
const delWorkCenter = async (data) => {
  const result = await api.post(`/info/workCenter/del`, { body: data })
  return result.data
}
// 작업장 리스트 엑셀 다운로드
const downloadWorkCenterListExcel = async (data) => {
  const result = await api.get(`/info/workCenter/excel/download`, { options: { responseType: "blob" } })
  return result.data
}

// 게이트웨이 리스트
const getGateWayList = async (data) => {
  const result = await api.get(`/info/gateway/list?gatewayName=${data.gatewayName}`)
  return result.data
}
// 게이트웨이 추가
const addGateWay = async (data) => {
  const result = await api.post(`/info/gateway/add`, { body: data })
  return result.data
}
// 게이트웨이 정보
const getGateWay = async (data) => {
  const result = await api.get(`/info/gateway/info?gatewaySeq=${data.gatewaySeq}`)
  return result.data
}
// 게이트웨이 수정
const editGateWay = async (data) => {
  const result = await api.post(`/info/gateway/mod`, { body: data })
  return result.data
}
// 게이트웨이 삭제
const delGateWay = async (data) => {
  const result = await api.post(`/info/gateway/del`, { body: data })
  return result.data
}
// 게이트웨이 리스트 엑셀 다운로드
const downloadGateWayListExcel = async (data) => {
  const result = await api.get(`/info/gateway/excel/download`, { options: { responseType: "blob" } })
  return result.data
}

// 센서노드 리스트
const getSensorNodeList = async (data) => {
  const result = await api.get(
    `/info/sensorNode/list?gatewaySeq=${data.gatewaySeq}&workCenterSeq=${data.workCenterSeq}&sensorNodeName=${data.sensorNodeName}`
  )
  return result.data
}
// 센서노드 추가
const addSensorNode = async (data) => {
  const result = await api.post(`/info/sensorNode/add`, { body: data })
  return result.data
}
// 센서노드 정보
const getSensorNode = async (data) => {
  const result = await api.get(`/info/sensorNode/info?sensorNodeSeq=${data.sensorNodeSeq}`)
  return result.data
}
// 센서노드 수정
const editSensorNode = async (data) => {
  const result = await api.post(`/info/sensorNode/mod`, { body: data })
  return result.data
}
// 센서노드 삭제
const delSensorNode = async (data) => {
  const result = await api.post(`/info/sensorNode/del`, { body: data })
  return result.data
}
// 센서노드 리스트 엑셀 다운로드
const downloadSensorNodeListExcel = async (data) => {
  const result = await api.get(`/info/sensorNode/excel/download`, { options: { responseType: "blob" } })
  return result.data
}
// 센서노드 센서 리스트
const getSensorList = async (data) => {
  const result = await api.get(`/info/sensor/list?sensorNodeSeq=${data.sensorNodeSeq}`)
  return result.data
}
// 센서노드 센서 추가
const addSensor = async (data) => {
  const result = await api.post(`/info/sensor/add`, { body: data })
  return result.data
}
// 센서노드 센서 정보
const getSensor = async (data) => {
  const result = await api.get(`/info/sensor/info?sensorSeq=${data.sensorSeq}`)
  return result.data
}
// 센서노드 센서 수정
const editSensor = async (data) => {
  const result = await api.post(`/info/sensor/mod`, { body: data })
  return result.data
}
// 센서노드 센서 삭제
const delSensor = async (data) => {
  const result = await api.post(`/info/sensor/del`, { body: data })
  return result.data
}

// 제어노드 리스트
const getControlNodeList = async (data) => {
  const result = await api.get(`/info/controlNode/list?${data}`)
  return result.data
}
// 제어노드 추가
const addControlNode = async (data) => {
  const result = await api.post(`/info/controlNode/add`, { body: data })
  return result.data
}
// 제어노드 정보
const getControlNode = async (data) => {
  const result = await api.get(`/info/controlNode/info?${data}`)
  return result.data
}
// 제어노드 수정
const editControlNode = async (data) => {
  const result = await api.post(`/info/controlNode/mod`, { body: data })
  return result.data
}
// 제어노드 삭제
const delControlNode = async (data) => {
  const result = await api.post(`/info/controlNode/del`, { body: data })
  return result.data
}
// 제어노드 리스트 엑셀 다운로드
const downloadControlNodeListExcel = async (data) => {
  const result = await api.get(`/info/controlNode/excel/download`, { options: { responseType: "blob" } })
  return result.data
}

export default {
  getFarmInfo,
  editFarmInfo,

  getUserList,
  addUser,
  getUser,
  editUser,
  delUser,
  downloadUserListExcel,

  getItemList,
  addItem,
  getItem,
  editItem,
  delItem,
  downloadItemListExcel,

  getCustomerList,
  addCustomer,
  getCustomer,
  editCustomer,
  delCustomer,
  downloadCustomerListExcel,

  getWorkCenterList,
  addWorkCenter,
  getWorkCenter,
  editWorkCenter,
  delWorkCenter,
  downloadWorkCenterListExcel,

  getGateWayList,
  addGateWay,
  getGateWay,
  editGateWay,
  delGateWay,
  downloadGateWayListExcel,

  getSensorNodeList,
  addSensorNode,
  getSensorNode,
  editSensorNode,
  delSensorNode,
  downloadSensorNodeListExcel,
  getSensorList,
  addSensor,
  getSensor,
  editSensor,
  delSensor,

  getControlNodeList,
  addControlNode,
  getControlNode,
  editControlNode,
  delControlNode,
  downloadControlNodeListExcel,
}
