import { handleActions } from "redux-actions"
import actions from "../actions"

// action type load
const {
    SET_MODULE_LIST,
    SET_MENU_LIST,
    SET_CODE_LIST,
    SET_CODE_HELP_ITEM,
    SET_CODE_HELP_CUSTOMER,
    SET_COMBO_WORK_CENTER_LIST,
    SET_COMBO_GATEWAY_LIST,
    SET_COMBO_CONTROL_NODE_LIST,
    SET_COMBO_EQUIP_LIST,
    SET_COMBO_STDCROP_LIST,
    SET_COMBO_CROP_LIST,
    SET_COMBO_CROP_GROWTH_LIST,
    SET_BOOK_MARK_LIST,

    SET_ENV_SETTING_INFO,
    INIT_USER_MAJOR_CODE,
    SET_USER_MAJOR_CODE,
    INIT_USER_MINOR_CODE,
    SET_USER_MINOR_CODE,
    SET_MODULE_AUTH_LIST,
    INIT_USER_PGM_AUTH_MENU_LIST,
    SET_USER_PGM_AUTH_MENU_LIST,
    INIT_USER_PGM_AUTH_LIST,
    SET_USER_PGM_AUTH_LIST,

    // 언어종류 가져오기
    INIT_LANGUAGEKIND,
    SET_LANGUAGEKIND,

    // 사용자언어 다국어
    // 대분류 - 차건담 2023.01.30
    INIT_UMAJOR_SMAJOR_SMINOR,
    SET_UMAJOR_SMAJOR_SMINOR,
    // 소분류
    INIT_USER_UDLanguage_CODE,
    SET_USER_UDLanguage_CODE,

    // 시스템언어 다국어 - 차건담 2023.01.20
    INIT_SYS_MAJOR_CODE,
    SET_SYS_MAJOR_CODE,

    INIT_SYS_MINOR_CODE,
    SET_SYS_MINOR_CODE,

    INIT_SYS_SDLANGUAGE_CODE,
    SET_SYS_SDLANGUAGE_CODE,

    //} = actions.SystemSettingAction
} = actions.SystemSettingAction

// default state value
const initialState = {
    _moduleList: [],
    _menuList: [],
    _codeList: [],
    _codeHelpItem: [],
    _codeHelpCustomer: [],
    _comboWorkCenterList: [],
    _comboGatewayList: [],
    _comboControlNodeList: [],
    _comboEquipList: [],
    _comboStdCropList: [],
    _comboCropList: [],
    _comboCropGrowthList: [],
    _bookmarkList: [],

    _envSettingInfo: {},
    _userMajorCode: [],
    _userMinorCode: [],
    _moduleAuthList: [],
    _userPgmAuthMenuList: [],
    _userPgmAuthList: [],

    _languageKind: [], // 언어종류

    _userUMajorSMajorSMinor: [], // 사용자언어 대분류, 시스템언어  대분류/소분류 다국어 - 차건담 2023.01.30

    _userUDLanguageCode: [], // 사용자언어 다국어 소분류코드 - 유용준
    _userSDLanguageCode: [], // 시스템언어 다국어 소분류코드 - 차건담 2023.01.20

    // 시스템코드 - 차건담 2023.01.20
    _sysMajorCode: [],
    _sysMinorCode: [],
}

// handle action
export default handleActions(
    {
        [SET_MODULE_LIST]: (state, action) => ({
            ...state,
            _moduleList: action.payload,
        }),
        [SET_MENU_LIST]: (state, action) => ({
            ...state,
            _menuList: action.payload,
        }),
        [SET_CODE_LIST]: (state, action) => ({
            ...state,
            _codeList: action.payload,
        }),
        [SET_CODE_HELP_ITEM]: (state, action) => ({
            ...state,
            _codeHelpItem: action.payload,
        }),
        [SET_CODE_HELP_CUSTOMER]: (state, action) => ({
            ...state,
            _codeHelpCustomer: action.payload,
        }),
        [SET_COMBO_WORK_CENTER_LIST]: (state, action) => ({
            ...state,
            _comboWorkCenterList: action.payload,
        }),
        [SET_COMBO_GATEWAY_LIST]: (state, action) => ({
            ...state,
            _comboGatewayList: action.payload,
        }),
        [SET_COMBO_CONTROL_NODE_LIST]: (state, action) => ({
            ...state,
            _comboControlNodeList: action.payload,
        }),
        [SET_COMBO_EQUIP_LIST]: (state, action) => ({
            ...state,
            _comboEquipList: action.payload,
        }),
        [SET_COMBO_STDCROP_LIST]: (state, action) => ({
            ...state,
            _comboStdCropList: action.payload,
        }),
        [SET_COMBO_CROP_LIST]: (state, action) => ({
            ...state,
            _comboCropList: action.payload,
        }),
        [SET_COMBO_CROP_GROWTH_LIST]: (state, action) => ({
            ...state,
            _comboCropGrowthList: action.payload,
        }),
        [SET_BOOK_MARK_LIST]: (state, action) => ({
            ...state,
            _bookmarkList: action.payload,
        }),

        [SET_ENV_SETTING_INFO]: (state, action) => ({
            ...state,
            _envSettingInfo: action.payload,
        }),
        [INIT_USER_MAJOR_CODE]: (state, action) => ({
            ...state,
            _userMajorCode: [],
        }),
        [SET_USER_MAJOR_CODE]: (state, action) => ({
            ...state,
            _userMajorCode: action.payload,
        }),
        [INIT_USER_MINOR_CODE]: (state, action) => ({
            ...state,
            _userMinorCode: [],
        }),
        [SET_USER_MINOR_CODE]: (state, action) => ({
            ...state,
            _userMinorCode: action.payload,
        }),

        // 공통 > 사용자 대분류, 시스템 대분류/소분류 - 차건담 2023.01.31
        [INIT_UMAJOR_SMAJOR_SMINOR]: (state, action) => ({
            ...state,
            _userUMajorSMajorSMinor: [],
        }),
        [SET_UMAJOR_SMAJOR_SMINOR]: (state, action) => ({
            ...state,
            _userUMajorSMajorSMinor: action.payload,
        }),

        // 사용자어언어 다국어
        // 소분류 - 유용준
        [INIT_USER_UDLanguage_CODE]: (state, action) => ({
            ...state,
            _userUDLanguageCode: [],
        }),
        [SET_USER_UDLanguage_CODE]: (state, action) => ({
            ...state,
            _userUDLanguageCode: action.payload,
        }),

        // 시스템언어 다국어 - 차건담 2023.01.20
        [INIT_SYS_SDLANGUAGE_CODE]: (state, action) => ({
            ...state,
            _userSDLanguageCode: [],
        }),
        [SET_SYS_SDLANGUAGE_CODE]: (state, action) => ({
            ...state,
            _userSDLanguageCode: action.payload,
        }),

        //
        [SET_MODULE_AUTH_LIST]: (state, action) => ({
            ...state,
            _moduleAuthList: action.payload,
        }),
        [INIT_USER_PGM_AUTH_MENU_LIST]: (state, action) => ({
            ...state,
            _userPgmAuthMenuList: [],
        }),
        [SET_USER_PGM_AUTH_MENU_LIST]: (state, action) => ({
            ...state,
            _userPgmAuthMenuList: action.payload,
        }),
        [INIT_USER_PGM_AUTH_LIST]: (state, action) => ({
            ...state,
            _userPgmAuthList: [],
        }),
        [SET_USER_PGM_AUTH_LIST]: (state, action) => ({
            ...state,
            _userPgmAuthList: action.payload,
        }),

        // 시스템코드 - 차건담 2023.01.20
        [INIT_SYS_MAJOR_CODE]: (state, action) => ({
            ...state,
            _sysMajorCode: [],
        }),
        [SET_SYS_MAJOR_CODE]: (state, action) => ({
            ...state,
            _sysMajorCode: action.payload,
        }),
        [INIT_SYS_MINOR_CODE]: (state, action) => ({
            ...state,
            _sysMinorCode: [],
        }),
        [SET_SYS_MINOR_CODE]: (state, action) => ({
            ...state,
            _sysMinorCode: action.payload,
        }),

        // 언어종류 - 차건담 2023.01.30
        [INIT_LANGUAGEKIND]: (state, action) => ({
            ...state,
            _languageKind: [],
        }),
        [SET_LANGUAGEKIND]: (state, action) => ({
            ...state,
            _languageKind: action.payload,
        }),
    },
    initialState
)
