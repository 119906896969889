import { handleActions } from "redux-actions"
import actions from "../actions"

// action type load
const { SET_INFO_PROFIT_AND_LOSS, SET_SALES_CROP_LIST, INIT_SENSOR_DATA_LIST_EXCEL, SET_SENSOR_DATA_LIST_EXCEL } = actions.StatusInfoAction

// default state value
const initialState = {
    _infoProfitAndLoss: [],
    _salesCropList: [],
    _sensorDataListExcel: [],
}

// handle action
export default handleActions(
    {
        [SET_INFO_PROFIT_AND_LOSS]: (state, action) => ({
            ...state,
            _infoProfitAndLoss: action.payload,
        }),
        [SET_SALES_CROP_LIST]: (state, action) => ({
            ...state,
            _salesCropList: action.payload,
        }),
        [INIT_SENSOR_DATA_LIST_EXCEL]: (state, action) => ({
            ...state,
            _sensorDataListExcel: [],
        }),
        [SET_SENSOR_DATA_LIST_EXCEL]: (state, action) => ({
            ...state,
            _sensorDataListExcel: action.payload,
        }),
    },
    initialState
)
