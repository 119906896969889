import { handleActions } from "redux-actions"
import actions from "../actions"

// action type load
const {
    //일정관리
    INIT_PRODUCTION_PLAN_LIST,
    SET_PRODUCTION_PLAN_LIST,
    INIT_PRODUCTION_PLAN,
    SET_PRODUCTION_PLAN,

    //방문자관리
    INIT_PRODUCTION_VISITOR_LIST,
    SET_PRODUCTION_VISITOR_LIST,
    INIT_PRODUCTION_VISITOR,
    SET_PRODUCTION_VISITOR,

    SET_CROP_PLAN_LIST,
    SET_WORK_CENTER_PLAN_LIST,

    SET_PRODUCTION_HARV_LIST,
    SET_PRODUCTION_HARV_WORKCENTER_LIST,
    SET_PRODUCTION_HARV_DAY_LIST,

    // 인력관리
    SET_PRODUCTION_STAFF_LIST,
    SET_PRODUCTION_STAFF_INFO,
    SET_PRODUCTION_STAFF_COMBO_LIST,

    // 외부인력사용현황조회
    SET_PRODUCTION_MANPOWER_LIST,
    SET_PRODUCTION_MANPOWER_INFO,
} = actions.ProductionAction

const { SET_SMINOR_COMBO_LIST, SET_UMINOR_COMBO_LIST, SET_WORKCENTER_COMBO_LIST, SET_CROP_COMBO_LIST, SET_CROPGROWTH_COMBO_LIST } =
    actions.CommonAction

// default state value
const initialState = {
    // 외부인력사용현황조회
    manpowerList: [],
    manpowerInfo: [],

    //일정관리
    _productionPlanList: [],
    _productionPlan: {},

    //방문자관리
    _productionVisitorList: [],
    _productionVisitor: {},

    // 인력관리
    _staffList: [],
    _staff: {},
    _staffInfo: {},
    _staffComboList: [],

    _sMinorComboList: [],
    _uMinorComboList: [],
    _workCenterComboList: [],
    _cropComboList: [],
    _cropGrowthComboList: [],
    //

    _cropPlanList: [],
    _cropWorkCenterPlanList: [],

    _productionHarvList: [],
    _productionHarvWorkCenterList: [],
    _productionDayList: [],
}

// handle action
export default handleActions(
    {
        // 외부인력사용현황조회
        [SET_PRODUCTION_MANPOWER_LIST]: (state, action) => ({
            ...state,
            manpowerList: action.payload,
        }),
        [SET_PRODUCTION_MANPOWER_INFO]: (state, action) => ({
            ...state,
            manpowerInfo: action.payload,
        }),

        //일정관리
        [INIT_PRODUCTION_PLAN_LIST]: (state, action) => ({
            ...state,
            _productionPlanList: [],
        }),
        [SET_PRODUCTION_PLAN_LIST]: (state, action) => ({
            ...state,
            _productionPlanList: action.payload,
        }),
        [INIT_PRODUCTION_PLAN]: (state, action) => ({
            ...state,
            _productionPlan: {},
        }),
        [SET_PRODUCTION_PLAN]: (state, action) => ({
            ...state,
            _productionPlan: action.payload,
        }),

        //방문자관리
        [INIT_PRODUCTION_VISITOR_LIST]: (state, action) => ({
            ...state,
            _productionVisitorList: [],
        }),
        [SET_PRODUCTION_VISITOR_LIST]: (state, action) => ({
            ...state,
            _productionVisitorList: action.payload,
        }),
        [INIT_PRODUCTION_VISITOR]: (state, action) => ({
            ...state,
            _productionVisitor: {},
        }),
        [SET_PRODUCTION_VISITOR]: (state, action) => ({
            ...state,
            _productionVisitor: action.payload,
        }),

        // 인력관리
        [SET_PRODUCTION_STAFF_LIST]: (state, action) => ({
            ...state,
            _staffList: action.payload,
        }),
        [SET_PRODUCTION_STAFF_INFO]: (state, action) => ({
            ...state,
            _staffInfo: action.payload,
        }),
        [SET_PRODUCTION_STAFF_COMBO_LIST]: (state, action) => ({
            ...state,
            _staffComboList: action.payload,
        }),

        [SET_SMINOR_COMBO_LIST]: (state, action) => ({
            ...state,
            _sMinorComboList: action.payload,
        }),
        [SET_UMINOR_COMBO_LIST]: (state, action) => ({
            ...state,
            _uMinorComboList: action.payload,
        }),
        [SET_WORKCENTER_COMBO_LIST]: (state, action) => ({
            ...state,
            _workCenterComboList: action.payload,
        }),
        [SET_CROP_COMBO_LIST]: (state, action) => ({
            ...state,
            _cropComboList: action.payload,
        }),
        [SET_CROPGROWTH_COMBO_LIST]: (state, action) => ({
            ...state,
            _cropGrowthComboList: action.payload,
        }),

        [SET_CROP_PLAN_LIST]: (state, action) => ({
            ...state,
            _cropPlanList: action.payload,
        }),
        [SET_WORK_CENTER_PLAN_LIST]: (state, action) => ({
            ...state,
            _cropWorkCenterPlanList: action.payload,
        }),
        [SET_PRODUCTION_HARV_LIST]: (state, action) => ({
            ...state,
            _productionHarvList: action.payload,
        }),
        [SET_PRODUCTION_HARV_WORKCENTER_LIST]: (state, action) => ({
            ...state,
            _productionHarvWorkCenterList: action.payload,
        }),
        [SET_PRODUCTION_HARV_DAY_LIST]: (state, action) => ({
            ...state,
            _productionDayList: action.payload,
        }),
    },
    initialState
)
