import { handleActions } from "redux-actions"
import actions from "../actions"

// action type load
const {
  // 표준작기정보
  INIT_STD_CROP_LIST,
  SET_STD_CROP_LIST,
  INIT_STD_CROP,
  SET_STD_CROP,

  INIT_STD_CROP_GROWTH_LIST,
  SET_STD_CROP_GROWTH_LIST,
  INIT_STD_CROP_GROWTH,
  SET_STD_CROP_GROWTH,

  INIT_STD_CROP_PEST_LIST,
  SET_STD_CROP_PEST_LIST,
  INIT_STD_CROP_PEST,
  SET_STD_CROP_PEST,

  INIT_STD_CROP_ENV_LIST,
  SET_STD_CROP_ENV_LIST,
  INIT_STD_CROP_ENV,
  SET_STD_CROP_ENV,

  INIT_STD_CROP_HRM_LIST,
  SET_STD_CROP_HRM_LIST,
  INIT_STD_CROP_HRM,
  SET_STD_CROP_HRM,
  // 작기정보
  INIT_CROP_LIST,
  SET_CROP_LIST,
  INIT_CROP,
  SET_CROP,

  INIT_CROP_GROWTH_LIST,
  SET_CROP_GROWTH_LIST,
  INIT_CROP_GROWTH,
  SET_CROP_GROWTH,

  INIT_CROP_PEST_LIST,
  SET_CROP_PEST_LIST,
  INIT_CROP_PEST,
  SET_CROP_PEST,

  INIT_CROP_ENV_LIST,
  SET_CROP_ENV_LIST,
  INIT_CROP_ENV,
  SET_CROP_ENV,

  INIT_CROP_HRM_LIST,
  SET_CROP_HRM_LIST,
  INIT_CROP_HRM,
  SET_CROP_HRM,

  // 재배일지
  SET_CROP_REPORT_LIST,
  SET_CROP_REPORT_INFO,
  SET_CROP_REPORT_COMBO_LIST,

  // 작기마감
  INIT_CROP_CLOSE_LIST,
  SET_CROP_CLOSE_LIST,
} = actions.CropManageAction

const {
  SET_SMINOR_COMBO_LIST,
  SET_UMINOR_COMBO_LIST,
  SET_WORKCENTER_COMBO_LIST,
  SET_CROP_COMBO_LIST,
  SET_CROPGROWTH_COMBO_LIST,
  // SET_STAFF_COMBO_LIST,
} = actions.CommonAction

// default state value
const initialState = {
  // 표준작기정보
  _stdCropList: [],
  _stdCrop: {},
  _stdCropGrowthList: [],
  _stdCropGrowth: {},
  _stdCropPestList: [],
  _stdCropPest: {},
  _stdCropEnvList: [],
  _stdCropEnv: {},
  _stdCropHRMList: [],
  _stdCropHRM: {},
  // 작기정보
  _cropList: [],
  _crop: {},
  _cropGrowthList: [],
  _cropGrowth: {},
  _cropPestList: [],
  _cropPest: {},
  _cropEnvList: [],
  _cropEnv: {},
  _cropHRMList: [],
  _cropHRM: {},
  // 재배일지
  _cropReportList: [],
  _cropReport: {},
  _cropReportInfo: {},
  _cropReportComboList: [],
  // _staffComboList: [],

  _sMinorComboList: [],
  _uMinorComboList: [],
  _workCenterComboList: [],
  _cropComboList: [],
  _cropGrowthComboList: [],

  // 작기마감
  _cropCloseList: [],
  _cropReportInfo: [],
}

// handle action
export default handleActions(
  {
    // 표준작기정보
    [INIT_STD_CROP_LIST]: (state, action) => ({
      ...state,
      _stdCropList: [],
    }),
    [SET_STD_CROP_LIST]: (state, action) => ({
      ...state,
      _stdCropList: action.payload,
    }),
    [INIT_STD_CROP]: (state, action) => ({
      ...state,
      _stdCrop: {},
    }),
    [SET_STD_CROP]: (state, action) => ({
      ...state,
      _stdCrop: action.payload,
    }),
    [INIT_STD_CROP_GROWTH_LIST]: (state, action) => ({
      ...state,
      _stdCropGrowthList: [],
    }),
    [SET_STD_CROP_GROWTH_LIST]: (state, action) => ({
      ...state,
      _stdCropGrowthList: action.payload,
    }),
    [INIT_STD_CROP_GROWTH]: (state, action) => ({
      ...state,
      _stdCropGrowth: {},
    }),
    [SET_STD_CROP_GROWTH]: (state, action) => ({
      ...state,
      _stdCropGrowth: action.payload,
    }),

    [INIT_STD_CROP_PEST_LIST]: (state, action) => ({
      ...state,
      _stdCropPestList: [],
    }),
    [SET_STD_CROP_PEST_LIST]: (state, action) => ({
      ...state,
      _stdCropPestList: action.payload,
    }),
    [INIT_STD_CROP_PEST]: (state, action) => ({
      ...state,
      _stdCropPest: {},
    }),
    [SET_STD_CROP_PEST]: (state, action) => ({
      ...state,
      _stdCropPest: action.payload,
    }),

    [INIT_STD_CROP_ENV_LIST]: (state, action) => ({
      ...state,
      _stdCropEnvList: [],
    }),
    [SET_STD_CROP_ENV_LIST]: (state, action) => ({
      ...state,
      _stdCropEnvList: action.payload,
    }),
    [INIT_STD_CROP_ENV]: (state, action) => ({
      ...state,
      _stdCropEnv: {},
    }),
    [SET_STD_CROP_ENV]: (state, action) => ({
      ...state,
      _stdCropEnv: action.payload,
    }),

    [INIT_STD_CROP_HRM_LIST]: (state, action) => ({
      ...state,
      _stdCropHRMList: [],
    }),
    [SET_STD_CROP_HRM_LIST]: (state, action) => ({
      ...state,
      _stdCropHRMList: action.payload,
    }),
    [INIT_STD_CROP_HRM]: (state, action) => ({
      ...state,
      _stdCropHRM: {},
    }),
    [SET_STD_CROP_HRM]: (state, action) => ({
      ...state,
      _stdCropHRM: action.payload,
    }),
    // 작기정보
    [INIT_CROP_LIST]: (state, action) => ({
      ...state,
      _cropList: [],
    }),
    [SET_CROP_LIST]: (state, action) => ({
      ...state,
      _cropList: action.payload,
    }),
    [INIT_CROP]: (state, action) => ({
      ...state,
      _crop: {},
    }),
    [SET_CROP]: (state, action) => ({
      ...state,
      _crop: action.payload,
    }),
    [INIT_CROP_GROWTH_LIST]: (state, action) => ({
      ...state,
      _cropGrowthList: [],
    }),
    [SET_CROP_GROWTH_LIST]: (state, action) => ({
      ...state,
      _cropGrowthList: action.payload,
    }),
    [INIT_CROP_GROWTH]: (state, action) => ({
      ...state,
      _cropGrowth: {},
    }),
    [SET_CROP_GROWTH]: (state, action) => ({
      ...state,
      _cropGrowth: action.payload,
    }),

    [INIT_CROP_PEST_LIST]: (state, action) => ({
      ...state,
      _cropPestList: [],
    }),
    [SET_CROP_PEST_LIST]: (state, action) => ({
      ...state,
      _cropPestList: action.payload,
    }),
    [INIT_CROP_PEST]: (state, action) => ({
      ...state,
      _cropPest: {},
    }),
    [SET_CROP_PEST]: (state, action) => ({
      ...state,
      _cropPest: action.payload,
    }),

    [INIT_CROP_ENV_LIST]: (state, action) => ({
      ...state,
      _cropEnvList: [],
    }),
    [SET_CROP_ENV_LIST]: (state, action) => ({
      ...state,
      _cropEnvList: action.payload,
    }),
    [INIT_CROP_ENV]: (state, action) => ({
      ...state,
      _cropEnv: {},
    }),
    [SET_CROP_ENV]: (state, action) => ({
      ...state,
      _cropEnv: action.payload,
    }),

    [INIT_CROP_HRM_LIST]: (state, action) => ({
      ...state,
      _cropHRMList: [],
    }),
    [SET_CROP_HRM_LIST]: (state, action) => ({
      ...state,
      _cropHRMList: action.payload,
    }),
    [INIT_CROP_HRM]: (state, action) => ({
      ...state,
      _cropHRM: {},
    }),
    [SET_CROP_HRM]: (state, action) => ({
      ...state,
      _cropHRM: action.payload,
    }),
    // 재배일지
    [SET_CROP_REPORT_LIST]: (state, action) => ({
      ...state,
      _cropReportList: action.payload,
    }),
    [SET_CROP_REPORT_INFO]: (state, action) => ({
      ...state,
      _cropReportInfo: action.payload,
    }),
    [SET_CROP_REPORT_COMBO_LIST]: (state, action) => ({
      ...state,
      _cropReportComboList: action.payload,
    }),
    [SET_SMINOR_COMBO_LIST]: (state, action) => ({
      ...state,
      _sMinorComboList: action.payload,
    }),
    [SET_UMINOR_COMBO_LIST]: (state, action) => ({
      ...state,
      _uMinorComboList: action.payload,
    }),
    [SET_WORKCENTER_COMBO_LIST]: (state, action) => ({
      ...state,
      _workCenterComboList: action.payload,
    }),
    [SET_CROP_COMBO_LIST]: (state, action) => ({
      ...state,
      _cropComboList: action.payload,
    }),
    [SET_CROPGROWTH_COMBO_LIST]: (state, action) => ({
      ...state,
      _cropGrowthComboList: action.payload,
    }),
    // [SET_STAFF_COMBO_LIST]: (state, action) => ({
    //   ...state,
    //   _staffComboList: action.payload,
    // }),
    // 작기마감
    [INIT_CROP_CLOSE_LIST]: (state, action) => ({
      ...state,
      _cropCloseList: [],
    }),
    [SET_CROP_CLOSE_LIST]: (state, action) => ({
      ...state,
      _cropCloseList: action.payload.sort((a, b) => b.cropSeq - a.cropSeq),
    }),
  },
  initialState
)
