const type = "prod"

const URL = {
    prod: {
        API_URL: "https://api.elefarm.net/farm",
    },
    dev: {
        API_URL: "https://api.elefarm.net/farm",
    },
    local: {
        API_URL: "http://localhost:3000",
    },
}

const TOKEN_ID = "elefarm_token"

module.exports = {
    API_URL: process.env.API_URL || URL[type].API_URL,
    TOKEN_ID,
    LANGUAGE_CODE: {
        10010001: "en", // 영어
        10010002: "ko", // 한국어
        10010003: "vi", // 베트남어
        10010004: "zh", // 중국어간체
        10010005: "tw", // 중국어본체
        10010006: "ja", // 일본어
    },
    DAUM_APP_KEY: "29e60393c26703789ddf69d607665a9b", //
}
