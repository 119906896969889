import { handleActions } from "redux-actions"
import actions from "../actions"

// action type load
const {
    SET_DATA_LIST,

    // 다국어 관련 - 차건담 2023.02.14
    INIT_TRANSLATION_WORDS,
    SET_TRANSLATION_WORDS,
} = actions.CommonAction

// default state value
const initialState = {
    _translationWords: [],
}

// handle action
export default handleActions(
    {
        [SET_DATA_LIST]: (state, action) => {
            state = {
                ...state,
                ...action.payload,
            }

            return state
        },

        // 다국어 TWord 초기화 - 차건담 2023.02.14
        [INIT_TRANSLATION_WORDS]: (state, action) => ({
            ...state,
            _translationWords: [],
        }),

        // 다국어 TWord 가져오기 - 차건담 2023.02.14
        [SET_TRANSLATION_WORDS]: (state, action) => ({
            ...state,
            _translationWords: action.payload,
        }),
    },
    initialState
)
