import { handleActions } from "redux-actions"
import actions from "../actions"

// action type load
const { SET_CROP_PLAN_LIST, SET_COST_PLAN_LIST, SET_PURCHASE_COST_LIST, SET_PURCHASE_COST, SET_PURCHASE_COST_DETAIL } = actions.PurchaseSpendAction

// default state value
const initialState = {
  _cropPlanList: [],
  _costPlanList: [],

  _purchaseCostList: [],
  _purchaseCost: {},
  _purchaseCostDetail: {},
}

// handle action
export default handleActions(
  {
    [SET_CROP_PLAN_LIST]: (state, action) => ({
      ...state,
      _cropPlanList: action.payload,
    }),
    [SET_COST_PLAN_LIST]: (state, action) => ({
      ...state,
      _costPlanList: action.payload,
    }),

    [SET_PURCHASE_COST_LIST]: (state, action) => ({
      ...state,
      _purchaseCostList: action.payload,
    }),
    [SET_PURCHASE_COST]: (state, action) => ({
      ...state,
      _purchaseCost: action.payload,
    }),
    [SET_PURCHASE_COST_DETAIL]: (state, action) => ({
      ...state,
      _purchaseCostDetail: action.payload,
    }),
  },
  initialState
)
