import { createAction } from "redux-actions"
import CommonApis from "../apis/CommonApis"

/* action type */
//
export const SET_PRODUCTION_MANPOWER_LIST = "@elefarm/production/SET_PRODUCTION_MANPOWER_LIST"
export const SET_PRODUCTION_MANPOWER_INFO = "@elefarm/production/SET_PRODUCTION_MANPOWER_INFO"

//일정관리
export const INIT_PRODUCTION_PLAN_LIST = "@elefarm/production/INIT_PRODUCTION_PLAN_LIST"
export const SET_PRODUCTION_PLAN_LIST = "@elefarm/production/SET_PRODUCTION_PLAN_LIST"
export const INIT_PRODUCTION_PLAN = "@elefarm/production/INIT_PRODUCTION_PLAN"
export const SET_PRODUCTION_PLAN = "@elefarm/production/SET_PRODUCTION_PLAN"

//방문자관리
export const INIT_PRODUCTION_VISITOR_LIST = "@elefarm/production/INIT_PRODUCTION_VISITOR_LIST"
export const SET_PRODUCTION_VISITOR_LIST = "@elefarm/production/SET_PRODUCTION_VISITOR_LIST"
export const INIT_PRODUCTION_VISITOR = "@elefarm/production/INIT_PRODUCTION_VISITOR"
export const SET_PRODUCTION_VISITOR = "@elefarm/production/SET_PRODUCTION_VISITOR"

export const SET_CROP_PLAN_LIST = "@elefarm/production/SET_CROP_PLAN_LIST"
export const SET_WORK_CENTER_PLAN_LIST = "@elefarm/production/SET_WORK_CENTER_PLAN_LIST"

export const SET_PRODUCTION_HARV_LIST = "@elefarm/production/SET_PRODUCTION_HARV_LIST"
export const SET_PRODUCTION_HARV_WORKCENTER_LIST = "@elefarm/production/SET_PRODUCTION_HARV_WORKCENTER_LIST"
export const SET_PRODUCTION_HARV_DAY_LIST = "@elefarm/production/SET_PRODUCTION_HARV_DAY_LIST"

//인력관리
export const SET_PRODUCTION_STAFF_LIST = "@elefarm/production/SET_PRODUCTION_STAFF_LIST"
export const SET_PRODUCTION_STAFF_INFO = "@elefarm/production/SET_PRODUCTION_STAFF_INFO"
export const SET_PRODUCTION_STAFF_COMBO_LIST = "@elefarm/production/SET_PRODUCTION_STAFF_COMBO_LIST"

/* action function */
export const setManpowerList = createAction(SET_PRODUCTION_MANPOWER_LIST)
export const setManpowerInfo = createAction(SET_PRODUCTION_MANPOWER_INFO)

//일정관리
export const initProductionPlanList = createAction(INIT_PRODUCTION_PLAN_LIST)
export const setProductionPlanList = createAction(SET_PRODUCTION_PLAN_LIST)
export const initProductionPlan = createAction(INIT_PRODUCTION_PLAN)
export const setProductionPlan = createAction(SET_PRODUCTION_PLAN)

//방문자관리
export const initProductionVisitorList = createAction(INIT_PRODUCTION_VISITOR_LIST)
export const setProductionVisitorList = createAction(SET_PRODUCTION_VISITOR_LIST)
export const initProductionVisitor = createAction(INIT_PRODUCTION_VISITOR)
export const setProductionVisitor = createAction(SET_PRODUCTION_VISITOR)

export const setCropPlanList = createAction(SET_CROP_PLAN_LIST)
export const setCropWorkCenterPlanList = createAction(SET_WORK_CENTER_PLAN_LIST)

export const setProductionHarvList = createAction(SET_PRODUCTION_HARV_LIST)
export const setProductionHarvWorkCenterList = createAction(SET_PRODUCTION_HARV_WORKCENTER_LIST)
export const setProductionDayList = createAction(SET_PRODUCTION_HARV_DAY_LIST)

// 인력관리
export const setStaffList = createAction(SET_PRODUCTION_STAFF_LIST)
export const setStaffInfo = createAction(SET_PRODUCTION_STAFF_INFO)
export const setStaffComboList = createAction(SET_PRODUCTION_STAFF_COMBO_LIST)

/* action api function */
// 작기별생산계획 리스트
export const getCropPlanList = (param) => async (dispatch) => {
    const result = await CommonApis.getApi("/product/crop/plan/list", param)
    if (result.code === 0) {
        dispatch(setCropPlanList(result.data.cropList))
        return true
    } else return false
}
// 작기별생산계획 작업장 리스트
export const getCropWorkCenterPlanList = (param) => async (dispatch) => {
    const result = await CommonApis.getApi("/product/crop/workCenter/plan/list", param)
    if (result.code === 0) {
        dispatch(setCropWorkCenterPlanList(result.data.cropWorkCenterList))
        return true
    } else return false
}
// 작기별생산계획 정보수정
export const editCropWorkCenterPlan = (param) => async (dispatch) => {
    const result = await CommonApis.postApi("/product/crop/workCenter/plan/mod", param)
    if (result.code === 0) {
        return true
    } else return false
}
// 작기별생산계획 삭제
export const delCropWorkCenterPlan = (param) => async (dispatch) => {
    const result = await CommonApis.postApi("/product/crop/workCenter/plan/del", param)
    if (result.code === 0) {
        return true
    } else return false
}

// 일정관리
// 일정관리 월 리스트
export const getProductionPlanMonthList = (param) => async (dispatch) => {
    const result = await CommonApis.getApi("/product/plan/month/list", param)
    if (result.code === 0) {
        dispatch(setProductionPlanList(result.data.planMonthList))
        return true
    } else return false
}
// 일정관리 일 리스트
export const getProductionPlanDayList = (param) => async (dispatch) => {
    const result = await CommonApis.getApi("/product/plan/day/list", param)
    if (result.code === 0) {
        dispatch(setProductionPlanList(result.data.PlanDayList))
        return true
    } else return false
}
// 일정관리 추가
export const addProductionPlan = (param) => async (dispatch) => {
    const result = await CommonApis.postApi("/product/plan/add", param)
    if (result.code === 0) {
        return true
    } else return false
}
// 일정관리 정보
export const getProductionPlan = (param) => async (dispatch) => {
    const result = await CommonApis.getApi("/product/plan/info", param)
    if (result.code === 0) {
        dispatch(setProductionPlan(result.data.PlanInfo[0]))
        return true
    } else return false
}
// 일정관리 정보수정
export const editProductionPlan = (param) => async (dispatch) => {
    const result = await CommonApis.postApi("/product/plan/mod", param)
    if (result.code === 0) {
        return true
    } else return false
}
// 일정관리 삭제
export const delProductionPlan = (param) => async (dispatch) => {
    const result = await CommonApis.postApi("/product/plan/del", param)
    if (result.code === 0) {
        return true
    } else return false
}

// 방문자관리
// 방문자관리 월 리스트
export const getProductionVisitorMonthList = (param) => async (dispatch) => {
    const result = await CommonApis.getApi("/product/visitor/month/list", param)
    if (result.code === 0) {
        dispatch(setProductionVisitorList(result.data.visitorMonthList))
        return true
    } else return false
}
// 방문자관리 일 리스트
export const getProductionVisitorDayList = (param) => async (dispatch) => {
    const result = await CommonApis.getApi("/product/visitor/day/list", param)
    if (result.code === 0) {
        dispatch(setProductionVisitorList(result.data.visitorDayList))
        return true
    } else return false
}
// 방문자관리 추가
export const addProductionVisitor = (param) => async (dispatch) => {
    const result = await CommonApis.postApi("/product/visitor/add", param)
    if (result.code === 0) {
        return true
    } else return false
}
// 방문자관리 정보
export const getProductionVisitor = (param) => async (dispatch) => {
    const result = await CommonApis.getApi("/product/visitor/info", param)
    if (result.code === 0) {
        dispatch(setProductionVisitor(result.data.VisitorInfo[0]))
        return true
    } else return false
}
// 방문자관리 정보수정
export const editProductionVisitor = (param) => async (dispatch) => {
    const result = await CommonApis.postApi("/product/visitor/mod", param)
    if (result.code === 0) {
        return true
    } else return false
}
// 방문자관리 삭제
export const delProductionVisitor = (param) => async (dispatch) => {
    const result = await CommonApis.postApi("/product/visitor/del", param)
    if (result.code === 0) {
        return true
    } else return false
}

// 생산수확조희
// 생산수확조회 작기 리스트
export const getProductionHarvList = (param) => async (dispatch) => {
    const result = await CommonApis.getApi("/product/harv/list", param)
    if (result.code === 0) {
        dispatch(setProductionHarvList(result.data.harvList))
        return true
    } else return false
}

// 생산수확조회 작업장 리스트
export const getProductionHarvWorkCenterList = (param) => async (dispatch) => {
    const result = await CommonApis.getApi("/product/harv/workCenter/list", param)
    if (result.code === 0) {
        dispatch(setProductionHarvWorkCenterList(result.data.workCenterList))
        return true
    } else return false
}

// 생산수확조회 일별 리스트
export const getProductionDayList = (param) => async (dispatch) => {
    const result = await CommonApis.getApi("/product/harv/day/list", param)
    if (result.code === 0) {
        dispatch(setProductionDayList(result.data.dayList))
        return true
    } else return false
}

//인력관리
// 인력관리 리스트
export const getStaffList = (param) => async (dispatch) => {
    const result = await CommonApis.getApi("/product/staff/list", param)
    if (result.code === 0) {
        dispatch(setStaffList(result.data.staffList))
        return true
    } else return false
}
// 인력관리 정보
export const getStaffInfo = (param) => async (dispatch) => {
    const result = await CommonApis.getApi("/product/staff/info", param)
    if (result.code === 0) {
        dispatch(setStaffInfo(result.data.staffInfo))
        return true
    } else return false
}
// 인력관리 정보추가
export const addStaff = (param) => async (dispatch) => {
    const result = await CommonApis.postApi("/product/staff/add", param)
    if (result.code === 0) {
        return true
    } else return false
}
// 인력관리 정보수정
export const editStaff = (param) => async (dispatch) => {
    const result = await CommonApis.postApi("/product/staff/mod", param)
    if (result.code === 0) {
        return true
    } else return false
}
// 인력관리 삭제
export const delStaff = (param) => async (dispatch) => {
    const result = await CommonApis.postApi("/product/staff/del", param)
    if (result.code === 0) {
        return true
    } else return false
}
// 인력관리 엑셀다운로드
export const getStaffExcelDown = (param) => async (dispatch) => {
    const result = await CommonApis.getApi("/product/staff/excel/download", param, { responseType: "blob" })
    return result
}
// 인력관리 콤보리스트
export const getStaffComboList = (param) => async (dispatch) => {
    const result = await CommonApis.getApi("/system/combo/list", param)
    if (result.code === 0) {
        if (param.queryKind) {
            switch (param.queryKind) {
                case "Crop":
                    dispatch(setStaffComboList(result.data.dataList))
                    break
                case "WorkCenter":
                    dispatch(setStaffCenterComboList(result.data.dataList))
                    break
            }
            return true
        } else return false
    }
}

export const getManpowerList = (param) => async (dispatch) => {
    const result = await CommonApis.getApi("/product/manpower/list", param)
    if (result.code === 0) {
        dispatch(setManpowerList(result.data.manpowerList))
        return true
    } else return false
}

export const getManpowerInfo = (param) => async (dispatch) => {
    const result = await CommonApis.getApi("/product/manpower/info", param)
    if (result.code === 0) {
        dispatch(setManpowerInfo(result.data.manpowerInfo))
        return true
    } else return false
}
