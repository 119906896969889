import { handleActions } from "redux-actions"
import actions from "../actions"

// action type load
const { SET_SALES_CROP_LIST, SET_SALES_CROP_PLAN_LIST, SET_SALES_CROP_PLAN } = actions.SalesManageAction

// default state value
const initialState = {
  // 작기별판매계획
  _salesCropList: [],
  _salesCropPlanList: [],
  _salesCropPlan: {},
}

// handle action
export default handleActions(
  {
    // 작기별판매계획
    [SET_SALES_CROP_LIST]: (state, action) => ({
      ...state,
      _salesCropList: action.payload,
    }),
    [SET_SALES_CROP_PLAN_LIST]: (state, action) => ({
      ...state,
      _salesCropPlanList: action.payload,
    }),
    [SET_SALES_CROP_PLAN]: (state, action) => ({
      ...state,
      _salesCropPlan: action.payload,
    }),
  },
  initialState
)
