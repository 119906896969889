import moment from "moment"
import * as xlsx from "xlsx"

// UI 작업용 길이 변환 함수
export const WidthToDP = (px) => {
    if (typeof window !== "undefined") {
        // 제플린 화면 넓이가 360 일 때
        // const screenWidth = window.innerWidth
        // return (screenWidth / 360) * px
        return px
    }
}

// 개발용 콘솔 로그
export const debug = (data) => {
    if (process.env.NODE_ENV === "development") {
        console.log(data)
    }
}

// 웹인지 앱인지 구분하는 녀석
export const isApp =
    typeof window !== "undefined" && (window.navigator.userAgent.includes("WISHRROM_Android") || window.navigator.userAgent.includes("WISHRROM_Ios"))

// 안드로이드 백 핸들러
export const androidBackHandler = (func) => {
    window.DataReturn = (_type) => {
        switch (_type) {
            case "back":
                func()
                break
            default:
                break
        }
    }
}

// 전화번호 포맷
export const formatPhone = (num) => {
    let formatNum = num + ""
    if (formatNum.length < 4) {
        formatNum = formatNum.substr(0, 3)
    } else if (formatNum.length < 8) {
        formatNum = formatNum.substr(0, 3) + "-" + formatNum.substr(3, 4)
    } else if (formatNum.length < 12) {
        formatNum = formatNum.substr(0, 3) + "-" + formatNum.substr(3, 4) + "-" + formatNum.substr(7, 4)
    }
    return formatNum
}

export const dateFormat = (day, format) => {
    return moment(day).format(format)
}

// 엑셀 다운로드 함수
export const downExcel = (props) => {
    const { result, fileName } = props
    const blob = new Blob([result], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
    })

    const link = document.createElement("a")
    const href = window.URL.createObjectURL(blob)

    link.href = href
    link.download = `${fileName}.xlsx`
    link.click()
}

/****************************************************************************************************
 * 2023.09.11 by 윤여광
 * Excel 파일 생성을 fronend에서 하도록 추가
 ****************************************************************************************************/
export const downExcelWeb = (props) => {
    const { result, fileName } = props

    if (result.length == 0) {
        console.error("No data to export")
        return
    }

    // 엑셀 워크북 및 워크시트 생성
    const wb = xlsx.utils.book_new()
    const ws = xlsx.utils.json_to_sheet(result)

    // 워크북에 워크시트 추가
    xlsx.utils.book_append_sheet(wb, ws, "Sheet1")

    // 엑셀 파일로 저장
    xlsx.writeFile(wb, fileName + "_" + moment().format("YYYYMMDD_HHmm") + ".xlsx")
}
